export default (editor, opts = {}) => {
  const component = {
    isComponent(el) {
      if (el?.tagName === "button" && el.getAttribute("componentName") === 'cubosuiteButton') {
        return { type: "cubosuiteButton" };
      }
    },
    model: {
      defaults: {
        tagName: "button",
        attributes: { type: "submit", componentName: "cubosuiteButton" },
        droppable: false, 
        components: [{ tagName: "span", components: "Enviar", editable: true }],
      },
    },
    view: {},
  };

  return component;
};
