export default (opts = {}) => {
    const script = function () {
        document.addEventListener('DOMContentLoaded', function () {

            var runAlready;
            var counting;

            function startCounter(a) {

                runAlready = true;
                counting = true;

                const progressBar = document.querySelectorAll("[progressBar]");
                progressBar.forEach((bar) => {
                    let progressValue = bar.querySelector("[endValue]");
                    let barProgress = bar.querySelector("[bar]")

                    let progressStartValue = 0;
                    let progressEndValue = progressValue.getAttribute('endValue');
                    let speed = 50;

                    if (progressEndValue != 0) {
                        let progress = setInterval(() => {
                            progressStartValue++;
                            progressValue.textContent = `${progressStartValue}%`

                            barProgress.style.width = `${progressStartValue}%`

                            if (progressStartValue == progressEndValue) {
                                clearInterval(progress);
                            }
                        }, speed);
                    }
                })
            }
            window.addEventListener('scroll', function () {
                var elements = document.querySelectorAll('[envalue]');
                var b = 0;
                elements.forEach(function (element) {
                    var oTop = element.offsetTop - window.innerHeight;
                    if (!runAlready & window.scrollY > oTop) {
                        startCounter(b);
                    }
                });
            });
        });
    };

    const component = {
        model: {
            defaults: {
                script,
                attributes: { progressBar: '' },
                components: [
                    {
                        type: 'div',
                        style: {
                          'display': 'flex', 
                          'justify-content': 'space-between'
                        },
                        components: [
                            {
                                type: 'text',
                                content: 'Lorem',
                                style: {
                                    'font-size': '18px', 
                                    'color': '#999999' 
                                },
                            },
                            {
                                type: 'default',
                                content: '0%',
                                attributes: { endValue: '0' },
                                style: {
                                    'font-size': '18px', 
                                    'color': '#333333',
                                    'font-weight': '700'
                                },
                                traits: [
                                    {
                                        type: 'text',
                                        label: 'Valor',
                                        name: 'endValue',
                                    },
                                    {
                                        type: 'id',
                                        name: 'id',
                                        Label: 'id',
                                    }
                                ],
                            }
                        ]
                    },
                    {
                        type: 'div',
                        style:{
                            'width': '100%', 
                            'background': '#EEEEEE', 
                            'height': '12px', 
                            'margin-top': '15px',
                        },
                        components: [
                            {
                                type: 'div',
                                attributes: { bar: '' },
                                style: {
                                    'background': '#CBCBCB', 
                                    'width': '2%',
                                    'height': '12px'
                                },
                            }
                        ]
                    }
                ],
            },
        },
        view: {

        },
    };
    return component;
};