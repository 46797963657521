import block from "./block.js";
import component from "./components/index.js"
import columnCall1 from "./components/columnCall1.js";
import columnForm1 from "./components/columnForm1.js";
import columns1 from "./components/columns1.js";
import divForm1 from "./components/divForm1.js";
import principalContainer1 from "./components/principalContainer1.js";
import scrollDown from "./components/scrollDown.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("topPage1", component(opts));
  editor.DomComponents.addType("columnCall", columnCall1(opts));
  editor.DomComponents.addType("columnForm", columnForm1(opts));
  editor.DomComponents.addType("columns1", columns1(opts));
  editor.DomComponents.addType("divForm", divForm1(opts));
  editor.DomComponents.addType("principalContainer", principalContainer1(opts));
  editor.DomComponents.addType("scrollDown", scrollDown(opts));

    //block
  editor.BlockManager.add("topPage1", block(opts));
};