import block from "./block.js";
import component from "./components/index.js"
import columnItemProducts4 from "./components/columnItemProducts4.js";
import columnProducts4 from "./components/columnProducts4.js";
import columnsProducts4 from "./components/columnsProducts4.js";
import containerProducts4 from "./components/containerProducts4.js";
import itemProducts4 from "./components/itemProducts4.js";
import rowProducts4 from "./components/rowProducts4.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("products4", component(opts));
  editor.DomComponents.addType("columnItemProducts4", columnItemProducts4(opts));
  editor.DomComponents.addType("columnProducts4", columnProducts4(opts));
  editor.DomComponents.addType("columnsProducts4", columnsProducts4(opts));
  editor.DomComponents.addType("containerProducts4", containerProducts4(opts));
  editor.DomComponents.addType("itemProducts4", itemProducts4(opts));
  editor.DomComponents.addType("rowProducts4", rowProducts4(opts));

    //block
  editor.BlockManager.add("products4", block(opts));
};