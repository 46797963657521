export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname:'div', 
              name: 'Colunas Produtos',
              attributes: { class: 'row' },
              components: [
                {
                  type: 'columnProducts4'
                },
                {
                  type: 'columnItemProducts4'
                }  
              ],
              styles: `
             .row{
                display: flex; 
                flex-direction:row; 
                width:100%; 
             }
             @media (max-width:992px){
              .row{
                flex-direction:column;
              }
             }
            `,
             },
          },
          view: {
      
          },
    };
  
    return component;
  };
  