export default (opts = {}) => {
    const component = {
      model: {
        defaults: {
          tagname: "div",
          name: "Coluna Sobre",
          attributes: { class: "column column-50" },
          components: [
            {
              type: "image",
              attributes: { class: "featured-image", src: "https://placehold.co/599x712" },
            },
          ],
          styles: `
         .featured-image{
          width:100%; 
          height:100%; 
          object-fit: cover;
         }
        `,
        },
      },
      view: {},
    };
  
    return component;
  };
  