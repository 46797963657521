export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              type:'Container', 
              name: 'Container Parceiros',
              attributes: { class: 'container' },
              components: [
                {
                  type: 'rowPartners1', 
                },
                
              ],
              styles: `
             .container{
                max-width: 1600px; 
             }
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};