export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              type:'div', 
              name: 'Seção Serviços',
              attributes: { class: 'section d-column' },
              components: [
                {
                  type: 'columnService3',
                },
                {
                  type: 'containerCardService3', 
                },
                {
                  type: 'button',
                }, 
              ],
              styles: `
             .section{
               position:relative; 
               with:100vw; 
               padding: 75px 5%; 
             }
             .d-column{
              display:flex;
              flex-direction: column; 
              align-items:center; 
             }
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};