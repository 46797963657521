import section from "./section/index.js";
import container from "./container/index.js";
import column2 from "./columns2/index.js";
import columns3 from "./columns3/index.js";
import columns4 from "./columns4/index.js";
import button from "./button/index.js";
import select from "./select/index.js";
import title from "./title/index.js";
import textBlock from "./textBlock/index.js";
import image from "./image/index.js";
import video from "./video/index.js";
import link from "./link/index.js";
import counters from "./counters/index.js";
import circularProgress from "./circularProgress/index.js";
import progressBar from "./progressBar/index.js";

export default (editor, opts) => {
    section(editor, opts)
    container(editor, opts)
    column2(editor, opts)
    columns3(editor, opts)
    columns4(editor, opts)
    button(editor, opts)
    select(editor, opts)
    title(editor, opts)
    textBlock(editor, opts)
    image(editor, opts)
    video(editor, opts)
    link (editor, opts)
    counters(editor, opts)
    circularProgress(editor, opts)
    progressBar(editor, opts)
}