export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              name: 'Item Produtos',
              attributes: { class: 'item-products-4' },
              components: [
                {
                    type: 'image', 
                    attributes: { class: 'picture-products-3', src: 'https://placehold.co/350x390'}, 
                },
                {
                    type: 'div',
                    attributes: { class: 'div-name-products-3'},
                    components: [
                        {
                            type: 'text',
                            content: 'Nome do Produto',
                            attributes: { class: 'name-products-3'}
                        },    
                    ]
                }        
              ],
              styles: `
              .item-products-4{
                width: 31%; 
                display: flex;
                flex-direction: column; 
                align-items: center; 
              }
              .picture-products-3{
                width: 100%;     
              }
              .div-name-products-3{
                width: 100%; 
                padding:20px; 
                background: #F9F9F9; 
                display: flex;
                flex-direction: column; 
                align-items: center;  
              }
              .name-products-3{
                font-size: 16px; 
                color: #95A1BB;
                text-align: center; 
              }
              @media (max-width: 992px){
                .item-products-4{
                    width: 100%; 
                }
              }
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};