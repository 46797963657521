export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname:'div', 
              name: 'Coluna Selos',
              attributes: { class: 'column column-50 coluna-stamps p-20' },
              components: [
                {
                  type: 'itemStamps2',  
                },
                
              ],
              styles: `
              .p-20{
                padding:20px;
              }
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};