export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname:'div', 
              name: "Rodapé",
              attributes: { class: 'section-footer' },
              components: [
                {
                  type: 'containerFooter', 
                },
                
              ],
              styles: `
             .section-footer{
               position:relative; 
               with:100vw; 
               padding: 20px 5%; 
               background: #95A1BB; 
             }
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};