import block from "./block.js";
import component from "./components/index.js"
import columnForm1 from "./components/columnForm1.js";
import columnImageForm1 from "./components/columnImageForm1.js";
import columnsForm1 from "./components/columnsForm1.js";
import containerForm1 from "./components/containerForm1.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("formBottom1", component(opts));
  editor.DomComponents.addType("columnForm1", columnForm1(opts));
  editor.DomComponents.addType("columnImageForm1", columnImageForm1(opts));
  editor.DomComponents.addType("columnsForm1", columnsForm1(opts));
  editor.DomComponents.addType("containerForm1", containerForm1(opts));

    //block
  editor.BlockManager.add("formBottom1", block(opts));
};