export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              name: 'Div',
              attributes: { class: 'time-line-container' },
              components: [
               {
                type: 'rowTimeLine1'
               },
               {
                type: 'rowTimeLine1'
               },
              ],
              styles: `
               .linha-tempo-container{
                 width:100%; 
                 margin-top:34px;
               }
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};