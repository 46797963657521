import { createWebHistory, createRouter } from "vue-router";

import Pagebuilder from "../components/Pagebuilder.vue";
import NotFound from "../components/NotFound.vue";
import VerifyToken from "../components/VerifyToken.vue";
import Unauthorized from "../components/Unauthorized.vue";

const routes = [
  {
    path: "/page/:id/:token",
    name: "VerifyToken",
    component: VerifyToken,
  },
  {
    path: "/page/:id",
    name: "Pagebuilder",
    component: Pagebuilder,
  },
  {
    path: "/unauthorized",
    name: "Unauthorized",
    component: Unauthorized,
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
