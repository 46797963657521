
const toolbar = (editor) => {
    const commands = editor.Commands;
    commands.add("command-up", (editor) => {
      const selectedComponent = editor.getSelected();
      const parent = selectedComponent.parent();
      const index = selectedComponent.collection.findIndex(selectedComponent);
      selectedComponent.move(parent, { at: index - 1 });
    });
    commands.add('command-down', (editor) => {
      const selectedComponent = editor.getSelected();
      const parent = selectedComponent.parent();
      const index = selectedComponent.collection.findIndex(selectedComponent);
      selectedComponent.move(parent, { at: index + 2 });
    });
  
     editor.on("component:selected", (component) => {
      const selectedIndex = component.collection.findIndex(
        component
      );
      const defaultToolbar = component.get("toolbar");
      const checkAlreadyExist1 = defaultToolbar.find(toolbar => toolbar.command === 'command-up');
      const checkAlreadyExist2 = defaultToolbar.find(toolbar => toolbar.command === 'command-down');
  
      if (!checkAlreadyExist1 || !checkAlreadyExist2) {
        defaultToolbar.unshift({
          id: 'moveup',
          attributes: { class: 'bi bi-arrow-up-circle', title: 'Mover para cima'},
          command: 'command-up'
        },
        {
          id: 'movedown',
          attributes: { class: 'bi bi-arrow-down-circle', title: 'Mover para baixo'},
          command: 'command-down'
        }
        );
        component.set("toolbar", defaultToolbar);
      }
    });
  };
  
  export default toolbar;