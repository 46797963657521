export default (opts = {}) => {
    const script = function () {
      document.addEventListener('DOMContentLoaded', function () {
        var runAlready;
        var counting;
        function startCounter(a) {
          runAlready = true;
          counting = true;
          var counterValues = document.querySelectorAll('[datacount]');
          counterValues.forEach(function (value) {
            value.innerText = a;
            var countTo = value.getAttribute('datacount');
            var countNum = parseInt(value.innerText);
            var animation = setInterval(function () {
              countNum++;
              value.innerText = Math.floor(countNum);
              if (countNum == countTo) {
                clearInterval(animation);
                counting = false;
              }
            }
              , 10);
          }
          );
        }
        window.addEventListener('scroll', function () {
          var elements = document.querySelectorAll('[datacount]');
          var b = 0;
          elements.forEach(function (element) {
            var oTop = element.offsetTop - 600;
            if (!runAlready && window.scrollY > oTop) {
              startCounter(b);
            }
          }
          );
        }
        );
      }
      );
  
      };
    const component = {
        model: {
            defaults: {
              script,
              type: 'default',
              content: '0',
              attributes: { datacount: '0'},
              traits: [
                {
                  type: 'text',
                  label: 'Valor',
                  name: 'datacount',
                },
                {
                  type: 'id',
                  name: 'id',
                  Label: 'id',
                }
              ],
            },
          },
          view: {
      
          },
};
  return component; 
};