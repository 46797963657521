export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
                tagname: 'div',
                name: "Coluna Numeros",
                attributes: { class: 'column column-50 position-relative p-20 gap-20' },
                components: [
                    {
                        type: 'progressBar',
                    },
                    {
                        type: 'progressBar',
                    },
                    {
                        type: 'progressBar',
                    },

                ],
                styles: `
              .image-numbers{
                max-width:100%;
             }
             .column{
              display:flex; 
              flex-direction:column;
              align-items: flex-start;
             }
             .column-50{
              width:50%; 
             }
             .gap-20{
                gap: 20px;
             }
             @media (max-width:992px){
              .column-50{
                width:100%; 
              }
             }
            `,
            },
        },
        view: {

        },
    };
    return component;
};