export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              name: 'Item Depoimentos',
              attributes: { class: 'item-testimony-4' },
              components: [
                {
                    type: 'image', 
                    attributes: { class: 'picture-testimony-4', src: 'https://placehold.co/260x160'}, 
                },
                {
                    type: 'text', 
                    content: 'The only moment, the only life we have is in the NOW. What happened a few moments or several years ago is gone, what will happen this evening.',
                    attributes: { class: 'text-testimony-4'}
                },
                {
                    type: 'text', 
                    content: 'PROSPECTA DIGITAL, Manager', 
                    attributes: { class: 'desc-testimony-4'}
                }                 
              ],
              styles: `
              .item-testimony-4{
                width: 33%; 
              }
              .picture-testimony-4{
                width: 100%; 
              }
              .text-testimony-4{
                font-size: 16px; 
                color: #363940; 
                margin-top: 20px; 
              }
              .desc-testimony-4{
                font-size: 12px; 
                color: #95A1BB; 
                margin-top: 15px; 
              }
              @media (max-width: 992px){
                .item-testimony-4{
                    width: 100%; 
                }
              }
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};