export default (editor, opts = {}) => {
  const block = {
    label: "Formulário Criação Cubo",
    content: { type: "cubosuiteCreate" },
    category: "Formulários",
    fromPlugin: 'basic',
    media: `<svg viewBox="0 0 128.18 144.05" xmlns="http://www.w3.org/2000/svg">
    <defs>
        <style>
            .cls-1{fill:#fff}
        </style>
    </defs>
    <g id="Camada_1-2" data-name="Camada 1">
        <path class="cls-1" d="M64.09 144.05c-2.21 0-4.41-.57-6.38-1.7L6.38 112.71C2.45 110.44 0 106.21 0 101.66V42.39a12.8 12.8 0 0 1 6.38-11.05L57.71 1.7c3.93-2.27 8.82-2.27 12.75 0l51.33 29.64c3.93 2.27 6.38 6.5 6.38 11.05v59.28a12.8 12.8 0 0 1-6.38 11.05l-51.33 29.64c-1.97 1.14-4.17 1.7-6.38 1.7Zm0-138.72c-1.28 0-2.57.33-3.71.99L9.04 35.95a7.445 7.445 0 0 0-3.71 6.43v59.28c0 2.64 1.42 5.11 3.71 6.43l51.33 29.64a7.465 7.465 0 0 0 7.43 0l51.33-29.64a7.445 7.445 0 0 0 3.71-6.43V42.39c0-2.64-1.42-5.11-3.71-6.43L67.8 6.32a7.448 7.448 0 0 0-3.71-.99Z"/>
        <path class="cls-1" d="M64.14 124.2c-1.47 0-2.66-1.19-2.66-2.66V69.98c0-1.07-.64-2.03-1.62-2.45l-37.83-16.1a2.67 2.67 0 0 1-1.41-3.49 2.67 2.67 0 0 1 3.49-1.41l38.99 16.6c.66.28 1.42.28 2.08 0l39.29-16.61c1.35-.57 2.92.06 3.49 1.42a2.66 2.66 0 0 1-1.42 3.49L68.42 67.54a2.66 2.66 0 0 0-1.63 2.45v51.55c0 1.47-1.19 2.66-2.66 2.66Z"/>
    </g>
    <circle style="stroke:#000;stroke-opacity:0;fill:#fff" cx="101.596" cy="120.422" r="23.151"/>
    <path d="M101.076 103.534c-.557.207-.985.69-1.112 1.245-.047.195-.073 2.732-.073 7.179v6.876l-7.045.02c-6.293.02-7.077.033-7.284.127-.348.161-.65.435-.858.79-.154.261-.18.382-.18.81 0 .429.026.549.18.81.208.355.51.63.858.791.207.093.991.107 7.284.127l7.045.02v6.857c0 4.7.02 6.963.073 7.197.094.429.402.837.831 1.105.287.174.381.201.83.201.435 0 .555-.027.816-.181.355-.207.63-.509.791-.857.093-.208.107-.991.127-7.278l.02-7.044 7.044-.02c6.287-.02 7.07-.034 7.278-.127.348-.161.65-.436.857-.791.154-.261.181-.381.181-.81 0-.428-.027-.549-.181-.81a1.945 1.945 0 0 0-.857-.79c-.208-.094-.991-.107-7.278-.127l-7.044-.02-.02-7.044c-.02-7.727.006-7.231-.422-7.734-.428-.508-1.265-.743-1.861-.522Z" style="fill:#032e3f"/>
</svg>
    `,
  };
  return block;
};
