import block from "./block.js";
import component from "./components/index.js"
import containerPortifolio1 from "./components/containerPortifolio1.js";
import gridPortifolio1 from "./components/gridPortifolio1.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("customerPortifolio1", component(opts));
  editor.DomComponents.addType("containerPortifolio1", containerPortifolio1(opts));
  editor.DomComponents.addType("gridPortifolio1", gridPortifolio1(opts));

    //block
  editor.BlockManager.add("customerPortifolio1", block(opts));
};