export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname: 'div',
              name: "Coluna Numeros",
              attributes: { class: 'column column-40 p-20 justify-center' },
              components: [
                {
                  type: 'text',
                  content: 'Subtítulo',
                  name: 'Subtitulo',
                  attributes: { class: 'subtitle text-uppercase' },
                },
                {
                  tagName: 'h2',
                  type: 'text',
                  name: 'Titulo',
                  components: 'Big Numbers',
                  attributes: { class: 'principal-title' },
                  traits: [
                    {
                      type: "select",
                      options: [
                        { value: "h1", name: opts.labelN1 },
                        { value: "h2", name: opts.labelN2 },
                        { value: "h3", name: opts.labelN3 },
                        { value: "h4", name: opts.labelN4 },
                        { value: "h5", name: opts.labelN5 },
                        { value: "h6", name: opts.labelN6 },
                      ],
                      label: 'Tamanho',
                      name: "tagName",
                      changeProp: 1,
                    },
                    {
                      type: 'id',
                      name: 'id',
                      Label: 'id',
                    },
                  ],
                },
                {
                  type: 'text',
                  content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et bibendum risus. Cras faucibus viverra dolor, at  ',
                  attributes: { class: 'body-text' },
                },
                {
                  type: 'button',
                },
              ],
              styles: `
               .principal-title{
                font-size: 30px; 
                color: #363940; 
                margin:0;
               }
               .body-text{
                font-size: 16px; 
                color: #363940; 
                margin-top:30px;
               }
               .subtitle{
                font-size: 13px; 
                color: #747474; 
               }
               .text-uppercase{
                text-transform: uppercase;
               }
              .p-20{
                padding:20px;
              }
              .column-40{
                width:40%; 
              }
              .column{
                display:flex; 
                flex-direction:column;
                align-items: flex-start;
               }
               .column-60{
                width:60%; 
               }
               .justify-center{
                justify-content: center; 
               }
              
              @media (max-width: 992px){ 
                .column-40{
                    width: 100%;
                }
                .column-60{
                    width:100%; 
                   }
              }
            `,
            },
          },
          view: {
      
          },
};
  return component; 
};