export default (opts = {}) => {
    const component = {
        model: {
            defaults: { 
              tagname:'div', 
              name: "Item Eventos",
              attributes: { class: 'item-events-1' },
              components: [      
                {
                  type:'image',
                  tagname:'img', 
                  attributes: { src:'https://placehold.co/536x270'}
                },
                {
                  type: 'text',
                  content:'Lorem ipsum dolor sit amet',
                  attributes: {class: 'title-event'},
                },
                {
                  type:'text', 
                  content:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris luctus tincidunt venenatis. Nulla viverra maximus eleifend. Quisque maximus gravida leo sed aliquet. Quisque luctus quam tortor, sit amet ultricies arcu molestie nec. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Duis nec aliquet urna. Proin eu lacus orci.',
                  attributes: {class:'text-event'},
                }, 
              ],
              styles: `
              .item-events-1{
                width:50%; 
              }
              .text-event{
                font-size: 16px;
                color:#363940; 
                margin-top: 15px;
              }
              .title-event{
                font-size: 12px; 
                color:#95A1BB;
                margin-top:20px; 
                text-transform:uppercase; 
              }
              @media (max-width:992px){
                .item-events-1{
                  width:100%; 
                }
              }
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};