import block from "./block.js";
import component from "./components/index.js"
import containerTestimony4 from "./components/containerTestimony4.js";
import itemTestimony4 from "./components/itemTestimony4.js";
import rowTestimony4 from "./components/rowTestimony4.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("testimony4", component(opts));
  editor.DomComponents.addType("containerTestimony4", containerTestimony4(opts));
  editor.DomComponents.addType("itemTestimony4", itemTestimony4(opts));
  editor.DomComponents.addType("rowTestimony4", rowTestimony4(opts));

  //block
  editor.BlockManager.add("testimony4", block(opts));
};