export default (opts = {}) => {
    const script = function () {
        const tabLinks = document.querySelectorAll(".tab-link");
        const tabContents = document.querySelectorAll(".tab-content");
    
        function showTab(tabIndex) {
          tabContents.forEach(function (tab) {
            tab.style.display = "none";
          });
          tabLinks.forEach(function (tab) {
            tab.classList.remove("active");
          });
          tabContents[tabIndex].style.display = "block";
          tabLinks[tabIndex].classList.add("active");
        }
    
        tabLinks.forEach(function (tab, index) {
          tab.addEventListener("click", function () {
            showTab(index);
          });
        });
      };
    
    const component = {
        model: {
            defaults: {
              script,
              name: "Container FAQ",
              attributes: { class: 'faq-container' },
              components: [
                {
                  type: 'tabsFaq',
                },
                {
                  type: 'contentFaq',
                },
              ],
              styles: `
              .faq-container{
                width:100%; 
                margin-top:50px;
                display:flex;
                gap: 20px;
              }
              @media (max-width:992px){
                .faq-container{
                  flex-direction:column; 
                }
              }
            `,
            },
          },
          view: {
      
          },
};
  return component; 
};