export default (editor, opts = {}) => {
  const domc = editor.DomComponents;
  const pipes = opts.pipes ?? [];
  const customfields = opts.customfields ?? [];

  const script = function (props) {
    if (typeof Pristine === "undefined") {
      console.log('Pristine is undefined')
      var script = document.createElement("script");
      script.src =
        "https://cdn.jsdelivr.net/npm/pristinejs@1.1.0/dist/pristine.min.js";
      script.type = "text/javascript";
      document.getElementsByTagName("head")[0].appendChild(script);
    }
    setTimeout(() => {
      Pristine.addMessages("pt-BR", {
        required: "Campo obrigatório",
        email: "Este campo requer um endereço de e-mail válido",
        number: "Este campo requer um número válido",
        integer: "Este campo requer um número inteiro válido",
        url: "Este campo requer uma URL válida",
        tel: "Este campo requer um número de telefone válido",
        maxlength: "Este campo deve ter no máximo ${1} caracteres",
        minlength: "Este campo deve ter pelo menos ${1} caracteres",
        min: "O campo deve ter pelo menos ${1} caracteres",
        max: "Este campo deve ter no máximo ${1} caracteres",
        pattern: "Este campo deve corresponder ao padrão",
        equals: "Os campos não correspondem",
        default: "Este campo não é válido",
      });

      Pristine.setLocale("pt-BR");
    }, 500)

    if (typeof VMasker === "undefined") {
      var script = document.createElement("script");
      script.src =
        "//unpkg.com/vanilla-masker@1.1.1/build/vanilla-masker.min.js";
      script.type = "text/javascript";
      document.getElementsByTagName("head")[0].appendChild(script);
    }


    setTimeout(() => {
      const url = window.location.href;

      const storage = localStorage.getItem("cuboDeal");
      const deal = storage ? JSON.parse(storage) : null;

      if ((!deal || !deal.id) && (!url.includes('127.0.0.1') && !url.includes('builder.cubosuite.com.br'))) {
        if (props.redirect_if_not_filled) {
          window.location.href = props.redirect_if_not_filled
          return
        }

        alert('É necessario preencher a primeira etapa')

        return
      }

      let fields = this.querySelectorAll("input, select");

      this.querySelector('[name="name"]').value = deal && deal.people && deal.people.name ? deal.people.name : '';
      this.querySelector('[name="phone"]').value = deal && deal.people && deal.people.phone ? deal.people.phone : '';


      fields.forEach((field) => {
        let type = field.getAttribute("type");
        let id = field.getAttribute("name");
        let customfieldName = field.getAttribute("data-cf");
        const customfield = deal && deal.customfields.length > 0 ? deal.customfields.find((customfield) => customfield.customfield.id === id) : null;

        if (customfieldName !== 'phone' && customfieldName !== 'name' && customfield) {
          if (type === "text" || type === "email") {
            field.value = customfield.value;
          }

          if (type === "select") {
            field.value = customfield.value;
          }
        }

      });

      this.querySelectorAll("[mask]").forEach(input => VMasker(input).maskPattern(input.getAttribute('mask')))

    }, 200);

    this.addEventListener("click", (event) => {
      if (typeof dataLayer !== "undefined") {
        dataLayer.push({
          event: "formStart",
          eventModel: {
            form_id: this.getAttribute("id"),
            triggered_by: "CuboSuite"
          }
        })
      }
    }, { once: true });

    this.addEventListener("submit", (event) => {
      event.preventDefault();
      const button = event.target.querySelector("button[type='submit']")
      const buttonLabel = button.querySelector("button[type='submit'] span") ? button.querySelector("button[type='submit'] span") : button.querySelector("button[type='submit']")
      button.setAttribute("disabled", true);
      buttonLabel.innerText = "Enviando...";
      const deal = JSON.parse(localStorage.getItem("cuboDeal"));
      console.log('form-submit 2', deal)

      if (!deal.id || !props.keycubo) {
        return alert("API KEY / Id da Neg. não informada.");
      }

      let form = this;
      let pristine = new Pristine(this);
      let valid = pristine.validate();

      console.log('form-submit 3', valid)
      if (!valid) {
        button.removeAttribute("disabled")
        buttonLabel.innerText = "Enviar";
        return
      }

      if (valid) {
        let fields = form.querySelectorAll("input, select");

        if (!props.keycubo) {
          button.removeAttribute("disabled");
          buttonLabel.innerText = "Enviar";
          return alert("API KEY ou Funil não selecionados.");
        }

        let payload = {
          title: form.querySelector("[name='name']").value,
          peopleName: form.querySelector("[name='name']").value,
          peoplePhone: form.querySelector("[name='phone']").value,
          customfields: [],
        };

        fields.forEach((field) => {
          let type = field.getAttribute("type");
          let name = field.getAttribute("name");
          let customfieldName = field.getAttribute("data-cf");

          if (name !== "name" && name !== "phone") {
            if (type === "select") {
              if (field.options[field.selectedIndex].value) {
                payload.customfields.push({
                  customfieldId: parseInt(field.getAttribute("name")),
                  value: field.options[field.selectedIndex].value,
                });
              }
            } else {
              const name = customfieldName.toLowerCase().split(" ").join("_");
              const queryString = window.location.search;
              const urlParams = new URLSearchParams(queryString);
              if (!field.value)
                field.value = urlParams.get(name) ? urlParams.get(name) : null;

              if (field.value) {
                payload.customfields.push({
                  customfieldId: parseInt(field.getAttribute("name")),
                  value: field.value,
                });
              }
            }
          }
        });

        payload.customfields = payload.customfields.filter((cf) => cf.value);

        fetch(`https://api.cubosuite.com.br/deals/external/${deal.id}`, {
          method: "PUT",
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${props.keycubo}`,
          },
          body: JSON.stringify(payload),
        })
          .then((response) => response.json())
          .then((response) => {
            do {
              localStorage.setItem("cuboDeal", JSON.stringify(response));
            } while (localStorage.getItem("cuboDeal") == null)

            button.removeAttribute("disabled");

            if (typeof dataLayer !== "undefined")  {
              dataLayer.push({
                event: "formSubmit",
                eventModel: {
                  form_id: this.getAttribute("id"),
                  triggered_by: "CuboSuite"
                }
              })
            }

            if (props.redirect) {
              console.log('has redirect')
              buttonLabel.innerText =
                "Redirecionando...";
              window.location.href = props.redirect;
            }
            buttonLabel.innerText = "Enviado";
          });
      }
    });
  };

  const component = {
    isComponent(el) {
      if ('getAttribute' in el && ( el?.getAttribute("componentName") === 'cubosuiteUpdate' || el.getAttribute("componentName") === 'CubosuiteFormUpdate' || el.getAttribute('data-gjs-type') === 'CubosuiteFormUpdate' || el.getAttribute("componentName") === 'cubosuiteFormUpdate' ) ){
        return { type: "cubosuiteUpdate" };
      }
    },
    model: {
      defaults: {
        tagName: "form",
        attributes: { method: "POST", componentName: "cubosuiteUpdate" },
        droppable: false,
        traits: [
          {
            label: "Api key",
            type: "text",
            name: "keycubo",
            changeProp: true,
            required: true,
          },
          {
            label: "Redirecionar para",
            type: "text",
            name: "redirect",
            changeProp: true,
          },
          {
            label: "Redirecionar se não prencheu primeira etapa",
            type: "text",
            name: "redirect_if_not_filled",
            changeProp: true,
          },
        ],

        keycubo: "",
        redirect: "",

        script,

        "script-props": ["keycubo", "redirect", "redirect_if_not_filled"],

        components: (model) => {
          let inputs = `
          <div class="form-group">
            <label class="label">Nome</label>
            <input placeholder="Insira o nome" type="text" name="name" data-pristine-required class="form-control"/>
          </div>
          <div class="form-group">
            <label class="label">Telefone</label>
            <input placeholder="Insira o telefone" mask="(99) 99999-9999" type="text" data-pristine-required name="phone" data-pristine-minlength="15" class="form-control"/>
          </div>
          `;
          customfields.forEach((cf) => {

            let field = ``;
            if (cf.type === "text") {
              field = `
              <div class="form-group">
                <label class="label">${cf.name}</label>
                <input placeholder="Insira o ${cf.name}"  type="text" name="${cf.id
                }" class="form-control" data-cf="${cf.name}" />
              </div>`;
            }

            if (cf.type === "selectbox") {
              let options = `<option selected disabled value=''>Selecione um ${cf.name}</option>`;

              cf.options.forEach((opt) => {
                options += `<option value="${opt.value}">${opt.value}</option>`;
              });

              field = `
              <div class="form-group">
                <label class="label">${cf.name}</label>
                <select name="${cf.id}" type="select" class="form-control" data-cf="${cf.name}">
                ${options}
                </select>
              </div>`;
            }

            inputs += field;
          });

          inputs += `<button data-gjs-type="cubosuiteButton" class="btn btn-primary"><span>Enviar</span></button>`;

          return inputs;
        },
      },
    },
    view: {},
  };

  return component;
};
