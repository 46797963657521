export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname: 'div',
              name: "Coluna Modelos",
              attributes: { class: 'column column-50 position-relative' },
              components: [
                {
                  type: 'image',
                  attributes: { class: 'featured-image', src:'https://placehold.co/812x656'}, 
                },
      
              ],
              styles: `
             .column{
              display:flex; 
              flex-direction:column;
              align-items: flex-start;
             }
             .column-50{
              width:50%; 
             }
             .featured-image{
              width: 100%; 
              height: 100%; 
             }
             @media (max-width:992px){
              .column-50{
                width:100%; 
              }
             }
            `,
            },
          },
          view: {
      
          },
};
  return component; 
};