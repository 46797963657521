import block from "./block.js";
import component from "./components/index.js"
import cardServices2 from "./components/cardServices2.js";
import columnCardService2 from "./components/columnCardService2.js";
import columnService2 from "./components/columnService2.js";
import columnsService2 from "./components/columnsService2.js";
import containerService2 from "./components/containerService2.js";
import rowCardService2 from "./components/rowCardService2.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("service2", component(opts));
  editor.DomComponents.addType("cardServices2", cardServices2(opts));
  editor.DomComponents.addType("columnCardService2", columnCardService2(opts));
  editor.DomComponents.addType("columnService2", columnService2(opts));
  editor.DomComponents.addType("columnsService2", columnsService2(opts));
  editor.DomComponents.addType("containerService2", containerService2(opts));
  editor.DomComponents.addType("rowCardService2", rowCardService2(opts));

    //block
  editor.BlockManager.add("service2", block(opts));
};