export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              name: 'Item Tempo',
                attributes: { class: 'item-time-line-top-7' },
                components: [
                    {
                        type: 'text',
                        content: '2019  |  DEZ',
                        attributes: { class: 'date-line-7' }
                    },
                    {
                        type: 'text',
                        content: 'Lorem ipsum dolor sit amet',
                        attributes: { class: 'time-text-7' }
                    },
                    {
                        type: 'div',
                        attributes: { class: 'dot-7' }
                    },
                    {
                        type: 'image',
                        tagName: 'img',
                        attributes: { class: 'icon-time-line-7', src: 'https://placehold.co/189x189' },
                    },

                ],
                styles: `
              .dot-7{
                width: 15px; 
                height: 15px; 
                background: #363940; 
                border-radius: 100%; 
                margin-top: 15px;
              }
              .icon-time-line-7{
                width: 100%; 
                border-radius: 100%; 
              } 
              .item-time-line-top-7{
                width: 12%;
                z-index: 99;
                display: flex;
                flex-direction: column; 
                align-items: center; 
              }
              .date-line-7{
                font-size: 18px; 
                color: #363940; 
                margin-top: 15px;
              }
              .time-text-7{
                font-size: 16px; 
                color: #363940; 
                margin-top: 15px; 
              }

              @media (max-width: 992px){
                .item-time-line-top-7{
                    width: 47%; 
                  }
              }
            `,
            },
        },
        view: {

        },
    };
    return component;
};