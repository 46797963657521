export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname: 'div',
              name: "Coluna Numeros",
              attributes: { class: 'column column-50 position-relative p-20' },
              components: [
                {
                  tagname:'div',
                  attributes:{class:'row-numbers'}, 
                  components:[
                    {
                      type:'counters2',
                    },
                    {
                      type:'counters2',
                    },
                  ],
                },
      
              ],
              styles: `
             .row-numbers{
              display:flex; 
              gap: 30px; 
              justify-content:center;
              align-items: center;
              width:100%;
             }
             .column{
              display:flex; 
              flex-direction:column;
              align-items: flex-start;
             }
             .column-50{
              width:50%; 
             }
             @media (max-width:992px){
              .column-50{
                width:100%; 
              }
              .row-numbers{
                flex-direction:column; 
                width:100%; 
               align-items: center;
              }
             }
            `,
            },
          },
          view: {
      
          },
};
  return component; 
};