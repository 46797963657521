export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              type:'div', 
              name: "Seção Diferenciais",
              attributes: { class: 'section bg-cinza' },
              components: [
                {
                  type: 'containerDifferences3', 
                },
                
              ],
              styles: `
             .section{
               position:relative; 
               with:100vw; 
               padding: 75px 5%; 
             }
             .bg-cinza{
              background: #F9F9F9; 
             }
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};