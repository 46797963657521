export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname:'div', 
              name: "Div",
              attributes: { class: 'row-numbers' },
              components: [
                {
                  type:'counters3',
                },
                {
                  type:'counters3',
                },
                {
                  type:'counters3',
                },
                {
                  type:'counters3',
                },
                
              ],
              styles: `
                .row-numbers{
                  display:flex;
                  justify-content:center;
                  margin-top:50px;
                  gap:30px;
                }
                @media (max-width:992px){
                  .row-numbers{
                    flex-direction:column;
                    align-items:center;
                  }
                }
              
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};