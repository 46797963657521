export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              type:'image',
              attributes: {objectfit: ''},
              traits: [
                {
                  type: 'text', 
                  name:'src',
                  label: 'link',
                  id:'src',
                },
                {
                  type: 'text', 
                  name:'alt',
                  id:'alt',
                },
                {
                  type:'id',
                  name:'id',
                  Label:'id',
                },
                {
                  type:'checkbox',
                  name:'loading',
                  id:'loading',
                  label: 'Lazy Load',
                  valueTrue: 'lazy',
                  valueFalse: ''
                },
                {
                  type:'select',
                  name:'objectFit',
                  id:'objectFit',
                  label: 'Object Fit',
                  options: [
                  { value: 'initial', name: 'initial'},
                  { value: 'contain', name: 'contain'},
                  { value: 'cover', name: 'cover'},
                  { value: 'fill', name: 'fill'},
                  { value: 'scale-down', name: 'scale-down'},
                  { value: 'none', name: 'none'},
                ]
                },
              ],
              styles: '[objectfit="cover"] {object-fit: cover;} [objectfit="contain"] {object-fit: contain;} [objectfit="initial"] {object-fit: initial;} [objectfit="fill"] {object-fit: fill;} [objectfit="scale-down"] {object-fit: scale-down;} [objectfit="none"] {object-fit: none;}'
            },
          },
          view: {},
};
  return component; 
};