export default (opts = {}) => {
    const block = {
      category: "Layout",
      id: "columns4",
      label: "4 Colunas",
      fromPlugin: 'basic',
      media:'<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="59" height="35" viewBox="0 0 128 128"><image id="layout" width="128" height="128" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAQAAABpN6lAAAAAIGNIUk0AAHomAACAhAAA+gAAAIDoAAB1MAAA6mAAADqYAAAXcJy6UTwAAAACYktHRAAAqo0jMgAAAAlwSFlzAAAAYAAAAGAA8GtCzwAAAAd0SU1FB+cDHRUeNbpZ7m8AAAENSURBVHja7d0xCoAwAARBI/7/y7EXIhbCCO60sdgcFnZuW5IkSZI/GquDOXXayxdd3HTXYVoD6ACtAXSA1gA6QDuePjjG/fn1u+Frz6/8/g1oAB2gNYAO0BpAB2gNoAO0BtABWgPoAK0BdIDWADpAawAdoDWADtAaQAdoDaADtAbQAVoD6ACtAXSA1gA6QGsAHaA1gA7QGkAHaA2gA7QG0AFaA+gArQF0gNYAOkBrAB2gNYAO0BpAB2gNoAO0BtABWgPoAK0BdIDWADpAawAdoDWADtAaQAdoDaADtAbQAVoD6ACtAXSA1gA6QGsAHaA1gA7QGkAHaP1fQIdpDaADtAbQAVoD6IAkSZIkEU4uowzRdQNHAAAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMy0wMy0yOVQyMTozMDo1MyswMDowMEPnvNIAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjMtMDMtMjlUMjE6MzA6NTMrMDA6MDAyugRuAAAAKHRFWHRkYXRlOnRpbWVzdGFtcAAyMDIzLTAzLTI5VDIxOjMwOjUzKzAwOjAwZa8lsQAAAABJRU5ErkJggg=="/> </svg>',
      content: { type: "columns4" },
    };
  
    return block;
  };
  