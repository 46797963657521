export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname: 'div',
              name: 'Div',
              attributes: { class: 'w-100 space-beteween d-sm-column  gap-16' },
              components: [
                {
                  type: 'colServiceL5',
                },
                {
                  type: 'colCardServiceC5',
                },
                {
                  type: 'colCardServiceR5',
                },
      
              ],
              styles: `
               .space-beteween{
                display:flex; 
                justify-content: space-between; 
               }
               .margin-top-80{
                margin-top: 80px; 
               }
               .gap-16{
                gap:16px;
               }
               .w-100{
                width:100%; 
               }
               @media (max-width:992px){
                .d-sm-column{
                  flex-direction: column !important; 
                }
              }
            `,
            },
          },
          view: {
      
          },
};
  return component; 
};