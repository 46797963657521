export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname:'div', 
              name: 'Coluna Serviços',
              attributes: { class: 'w-25 w-sm-100 d-col-l gap-80' },
              components: [
               {
                type:'cardServicesLeft4',
               },
               {
                type:'cardServicesLeft4',
               },
               {
                type:'cardServicesLeft4',
               },
              ],
              styles: `
              .w-25{
                width:25%; 
              }
              .d-col-l{
                display: flex; 
                flex-direction: column; 
                align-items: flex-start; 
              }
              .gap-80{
                gap: 80px; 
              }
      
              @media (max-width:992px){
                .w-sm-100{
                  width: 100% !important; 
                }
              }
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};