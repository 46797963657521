import block from "./block.js";
import component from "./components/index.js"
import containerProducts1 from "./components/containerProducts1.js";
import rowProducts1 from "./components/rowProducts1.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("products1", component(opts));
  editor.DomComponents.addType("containerProducts1", containerProducts1(opts));
  editor.DomComponents.addType("rowProducts1", rowProducts1(opts));
  
    //block
  editor.BlockManager.add("products1", block(opts));
};