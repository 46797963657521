export default (opts = {}) => {
  const script = function () {
    document.addEventListener('DOMContentLoaded', function () {

      var runAlready;
      var counting;

      function startCounter(a) {
        runAlready = true;
        counting = true;

        const circularProgress = document.querySelectorAll("[circularContent]");
        circularProgress.forEach((form) => {
          let styles = window.getComputedStyle(form);
          console.log();
          let backColor = styles.backgroundColor
          let progressValue = form.querySelector("[endValue]");

          let progressStartValue = 0;
          let progressEndValue = progressValue.getAttribute('endValue');
          let speed = 50;

          if (progressEndValue != 0) {
            let progress = setInterval(() => {
              progressStartValue++;
              progressValue.textContent = `${progressStartValue}%`
              form.style.background = `conic-gradient(
              ${backColor} ${progressStartValue * 3.6}deg,  #D9DEEA ${progressStartValue * 3.6}deg)`

              if (progressStartValue == progressEndValue) {
                clearInterval(progress);
              }
            }, speed);
          }
        })
      };
      window.addEventListener('scroll', function () {
        var elements = document.querySelectorAll('.progress-value');
        var b = 0;
        elements.forEach(function (element) {
          var oTop = element.offsetTop - window.innerHeight;
          if (!runAlready & window.scrollY > oTop) {
            startCounter(b);
          }
        });
      });

    });
  };
  const component = {
    model: {
      defaults: {
        script,
        style: {
          'position': 'relative',
          'height': '230px',
          'width': '230px',
          'border-radius': '50%',
          'background': '#363940',
          'display': 'flex',
          'align-items': 'center',
          'justify-content': 'center',
        },
        attributes: { circularContent: ''},
        components: [
          {
            tagname: 'div',
            style: {
              'position':'absolute', 
              'height': '225px',
              'width': '225px',
              'border-radius': '50%',
              'background-color': '#fff',
              'display': 'flex',
              'flex-direction': 'column',
              'justify-content': 'center',
              'align-items': 'center'
            },
            components: [
              {
                type: 'default',
                content: '0%',
                attributes: { endValue: '0'},
                style: {
                  'font-size': '30px',
                  'font-weight': '500',
                  'color': '#363940',
                  'position': 'relative'
                },
                traits: [
                  {
                    type: 'text',
                    label: 'Valor',
                    name: 'endValue',
                  },
                  {
                    type: 'id',
                    name: 'id',
                    Label: 'id',
                  }
                ],
              },
              {
                type: 'text',
                content: 'Lorem Ipsum',
                style: {
                'font-size':'16px',
                'color':'#95A1BB',
                'margin-top':'10px',
                }
              },
            ],
          },
        ],
      },
    },
    view: {

    },
  };
  return component;
};