export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname: 'div',
              name: "Coluna Numeros",
              attributes: { class: 'column column-50 position-relative p-20 justify-center' },
              components: [
                {
                    type: 'text',
                    content: 'Subtítulo',
                    name: 'Subtitulo',
                    attributes: { class: 'subtitle text-uppercase' },
                  },
                  {
                    tagName: 'h2',
                    type: 'text',
                    name: 'Titulo',
                    components: 'Quem somos + Big Numbers',
                    attributes: { class: 'principal-title' },
                    traits: [
                      {
                        type: "select",
                        options: [
                          { value: "h1", name: opts.labelN1 },
                          { value: "h2", name: opts.labelN2 },
                          { value: "h3", name: opts.labelN3 },
                          { value: "h4", name: opts.labelN4 },
                          { value: "h5", name: opts.labelN5 },
                          { value: "h6", name: opts.labelN6 },
                        ],
                        label: 'Tamanho',
                        name: "tagName",
                        changeProp: 1,
                      },
                      {
                        type: 'id',
                        name: 'id',
                        Label: 'id',
                      },
                    ],
                  },
                {
                  type: 'image',
                  attributes: { class: 'image-numbers-5', src: 'https://placehold.co/812x693' }
                },
      
              ],
              styles: `
              .image-numbers-5{
                max-width:100%;
                margin-top: 50px;
             }
             .column{
              display:flex; 
              flex-direction:column;
              align-items: flex-start;
             }
             .principal-title{
                font-size: 30px; 
                color: #363940; 
                margin:0;
               }
             .column-50{
              width:50%; 
             }
             .subtitle{
                font-size: 13px; 
                color: #747474; 
               }
               .text-uppercase{
                text-transform: uppercase;
               }
               .p-20{
                padding:20px;
              }
              .justify-center{
                justify-content: center;
              }
             @media (max-width:992px){
              .column-50{
                width:100%; 
              }
             }
            `,
            },
          },
          view: {
      
          },
};
  return component; 
};