import block from "./block.js";
import component from "./components/index.js"
import containerUnits2 from "./components/containerUnits2.js";
import itemUnits2 from "./components/itemUnits2.js";
import rowUnits2 from "./components/rowUnits2.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("locationUnits2", component(opts));
  editor.DomComponents.addType("containerUnits2", containerUnits2(opts));
  editor.DomComponents.addType("itemUnits2", itemUnits2(opts));
  editor.DomComponents.addType("rowUnits2", rowUnits2(opts));

    //block
  editor.BlockManager.add("locationUnits2", block(opts));
};