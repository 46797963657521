import block from "./block.js";
import component from "./components/index.js"
import cardDifferences3 from "./components/cardDifferences3.js";
import columnDifferences3 from "./components/columnDifferences3.js";
import columnCardDifferences3 from "./components/columnCardDifferences3.js";
import columnsDifferences3 from "./components/columnsDifferences3.js";
import containerDifferences3 from "./components/containerDifferences3.js";
import rowCardDifferences3 from "./components/rowCardDifferences3.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("differences3", component(opts));
  editor.DomComponents.addType("cardDifferences3", cardDifferences3(opts));
  editor.DomComponents.addType("columnDifferences3", columnDifferences3(opts));
  editor.DomComponents.addType("columnCardDifferences3", columnCardDifferences3(opts));
  editor.DomComponents.addType("columnsDifferences3", columnsDifferences3(opts));
  editor.DomComponents.addType("containerDifferences3", containerDifferences3(opts));
  editor.DomComponents.addType("rowCardDifferences3", rowCardDifferences3(opts));

    //block
  editor.BlockManager.add("differences3", block(opts));
};