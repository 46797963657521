import block from "./block.js";
import component from "./components/index.js"
import accordion1 from "./components/accordion1.js";
import containerAccordion1 from "./components/containerAccordion1.js";
import containerFaq1 from "./components/containerFaq1.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("faq1", component(opts));
  editor.DomComponents.addType("accordion1", accordion1(opts));
  editor.DomComponents.addType("containerAccordion1", containerAccordion1(opts));
  editor.DomComponents.addType("containerFaq1", containerFaq1(opts));

    //block
  editor.BlockManager.add("faq1", block(opts));
};