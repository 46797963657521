import block from "./block.js";
import component from "./components/index.js"
import columnBigNumbers11 from "./components/columnBigNumbers11.js";
import columnNumbers11 from "./components/columnNumbers11.js";
import columnsNumbers11 from "./components/columnsNumbers11.js";
import containerNumbers11 from "./components/containerNumbers11.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("bigNumber11", component(opts));
  editor.DomComponents.addType("columnBigNumbers11", columnBigNumbers11(opts));
  editor.DomComponents.addType("columnNumbers11", columnNumbers11(opts));
  editor.DomComponents.addType("columnsNumbers11", columnsNumbers11(opts));
  editor.DomComponents.addType("containerNumbers11", containerNumbers11(opts));

    //block
  editor.BlockManager.add("bigNumber11", block(opts));
};