export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              type:'div', 
              name: "Seção Videos",
              attributes: { class: 'section position-relative' },
              components: [
                {
                  type: 'containerVideoFull', 
                },
                {
                  type:'video',
                  attributes:{class:'video-full'},
                },
              ],
              styles: `
             .section{
               position:relative; 
               with:100vw; 
               padding: 75px 5%; 
             }
             .position-relative{
              position:relative; 
             }
      
             .video-full{
              position:absolute;
              left:0;
              top:0;
              width:100%; 
              height: 100%;
             }
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};