import block from "./block.js";
import component from "./components/index.js"
import columnBigNumbers2 from "./components/columnBigNumbers2.js";
import columnNumbers2 from "./components/columnNumbers2.js";
import columnsNumbers2 from "./components/columnsNumbers2.js";
import containerNumbers2 from "./components/containerNumbers2.js";
import counters2 from "./components/counters2.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("bigNumber2", component(opts));
  editor.DomComponents.addType("columnBigNumbers2", columnBigNumbers2(opts));
  editor.DomComponents.addType("columnNumbers2", columnNumbers2(opts));
  editor.DomComponents.addType("columnsNumbers2", columnsNumbers2(opts));
  editor.DomComponents.addType("containerNumbers2", containerNumbers2(opts));
  editor.DomComponents.addType("counters2", counters2(opts));

    //block
  editor.BlockManager.add("bigNumber2", block(opts));
};