export default (editor, opts = {}) => {
  const block = {
    label: "Formulário Atualização Cubo",
    content: { type: "cubosuiteUpdate" },
    category: "Formulários",
    fromPlugin: 'basic',
    media: `
    <svg viewBox="0 0 128.18 144.05" xmlns="http://www.w3.org/2000/svg">
      <defs>
          <style>
              .cls-1{fill:#fff}
          </style>
      </defs>
      <g id="Camada_1-2" data-name="Camada 1">
          <path class="cls-1" d="M64.09 144.05c-2.21 0-4.41-.57-6.38-1.7L6.38 112.71C2.45 110.44 0 106.21 0 101.66V42.39a12.8 12.8 0 0 1 6.38-11.05L57.71 1.7c3.93-2.27 8.82-2.27 12.75 0l51.33 29.64c3.93 2.27 6.38 6.5 6.38 11.05v59.28a12.8 12.8 0 0 1-6.38 11.05l-51.33 29.64c-1.97 1.14-4.17 1.7-6.38 1.7Zm0-138.72c-1.28 0-2.57.33-3.71.99L9.04 35.95a7.445 7.445 0 0 0-3.71 6.43v59.28c0 2.64 1.42 5.11 3.71 6.43l51.33 29.64a7.465 7.465 0 0 0 7.43 0l51.33-29.64a7.445 7.445 0 0 0 3.71-6.43V42.39c0-2.64-1.42-5.11-3.71-6.43L67.8 6.32a7.448 7.448 0 0 0-3.71-.99Z"/>
          <path class="cls-1" d="M64.14 124.2c-1.47 0-2.66-1.19-2.66-2.66V69.98c0-1.07-.64-2.03-1.62-2.45l-37.83-16.1a2.67 2.67 0 0 1-1.41-3.49 2.67 2.67 0 0 1 3.49-1.41l38.99 16.6c.66.28 1.42.28 2.08 0l39.29-16.61c1.35-.57 2.92.06 3.49 1.42a2.66 2.66 0 0 1-1.42 3.49L68.42 67.54a2.66 2.66 0 0 0-1.63 2.45v51.55c0 1.47-1.19 2.66-2.66 2.66Z"/>
      </g>
      <circle style="stroke:#000;stroke-opacity:0;fill:#fff" cx="101.596" cy="120.422" r="23.151"/>
      <g>
          <path d="M2355 4684C1345 4575 562 3805 440 2800c-13-109-13-371 0-480 29-240 101-483 204-694C991 915 1694 460 2486 433c762-26 1481 356 1881 1000 101 163 213 418 213 485 0 111-96 211-204 212-103 0-177-55-215-160-89-245-275-516-470-687-593-518-1433-574-2087-138-119 79-278 223-366 332-429 527-508 1244-206 1848 83 164 166 281 298 419 521 543 1333 679 2000 335 177-92 389-254 479-368l31-39-190-169c-219-193-235-215-228-317 5-84 37-134 109-170l53-26h525c490 0 529 2 567 19 51 23 99 81 113 137 7 28 11 200 11 487 0 375-2 451-15 488-31 86-107 139-200 139-74 0-99-14-234-134-63-56-131-116-153-135l-39-33-137 133c-76 74-179 163-230 199-276 196-575 320-902 375-92 16-446 28-535 19z" style="fill:#032e3f" transform="matrix(.00768 0 0 -.00768 82.17 140.586)"/>
      </g>
    </svg>
    `,
  };
  return block;
};
