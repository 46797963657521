export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              type: 'Container',
              name: "Container Diferenciais",
              attributes: { class: 'container align-center' },
              components: [
                {
                  type:'text', 
                  content: 'Subtítulo', 
                  name:'Subtitulo',
                  attributes: {class:'subtitle text-uppercase'},
                }, 
                {
                  tagName: 'h2',
                  type: 'text',
                  name: 'Titulo',
                  components: 'Tópicos para diferenciais e informações curtas',
                  attributes: { class: 'principal-title' },
                  traits: [
                    {
                      type: "select",
                      options: [
                        { value: "h1", name: opts.labelN1 },
                        { value: "h2", name: opts.labelN2 },
                        { value: "h3", name: opts.labelN3 },
                        { value: "h4", name: opts.labelN4 },
                        { value: "h5", name: opts.labelN5 },
                        { value: "h6", name: opts.labelN6 },
                      ],
                      label: 'Tamanho',
                      name: "tagName",
                      changeProp: 1,
                    },
                    {
                      type: 'id',
                      name: 'id',
                      Label: 'id',
                    },
                  ],
                },
                
                {
                  type:'div',
                  attributes: { class: 'd-flex d-sm-column gap-60 margin-top-50'}, 
                  components: [
                    {
                      type: 'cardDifferences2',
                    },
                    {
                      type: 'cardDifferences2',
                    },
                    {
                      type: 'cardDifferences2',
                    },
                    {
                      type: 'cardDifferences2',
                    },
                  ]
                },
                {
                  type: 'button',
                },
              ],
              styles: `
             .container{
                max-width: 1600px; 
             }
             .principal-title{
              font-size: 30px; 
              color: #363940; 
              margin:0;
             }
             .body-text{
              font-size: 16px; 
              color: #363940; 
              margin-top:30px;
             }
             .subtitle{
              font-size: 13px; 
              color: #747474; 
             }
             .text-uppercase{
              text-transform: uppercase;
             }
             .d-flex{
              display: flex; 
             }
             .gap-60{
              gap: 60px; 
             }
             .margin-top-50{
              margin-top: 50px; 
             }
            .align-center{
              display:flex; 
              flex-direction: column; 
              align-items:center; 
            }
            @media (max-width:992px){
              .w-sm-100{
                width:100% !important;
              }
              .d-sm-column {
                flex-direction: column; 
              }
            }
            `,
            },
          },
          view: {
      
          },
};
  return component; 
};