export default (opts = {}) => {
    const component = {
        model: { 
            defaults: {
              name: "Contadores",
              attributes: { class:'counter-container-10'},
              components: [
                {
                  tagName: 'div',
                  attributes: { class: 'counters-10' },
                  components: [
                    {
                        type: 'text',
                        content: '+',
                        attributes: { class: 'prefix font-40'},
                      },
                    {
                      type:'counters',
                      attributes:{class:'counter-value font-40'},
                    },
                  ],
                },
                   {
                  type: 'text',
                  content: 'Lorem ipsum dolor sit amet consectetur ',
                  attributes: { class: 'context' }
                },
              ],
              styles: `
              .context{
                font-size: 16px; 
                color: #363940; 
                margin-left: 10px; 
              }
              .prefix, .counter-value{
                color:#363940; 
              }
              .counters-10{
                display:flex;
                aling-items:center;
              }
              .font-40{
                font-size:40px;
              }
              .font-20{
                font-size:20px; 
              }
              .light-gray{
                color:#A7A7A7;
              }
              .counter-container-10{
                display:flex;
                align-items:center; 
              }

              @media (max-width: 992px){
                .counter-container-10{
                    flex-direction: column; 
                  }
              }
           `,
            },
          },
          view: {
      
          },
};
  return component; 
};