export default (opts = {}) => {
  const component = {
    model: {
        defaults: {
          type:'div', 
          name: "Seção Sobre",
          attributes: { class: 'section' },
          components: [
            {
              type: 'containerAbout2'
            }             
          ],
          styles: `
         .section{
           position:relative; 
           with:100vw; 
           padding: 75px 5%; 
         }
        `,
         },
      },
      view: {
  
      },
  };

  return component;
};

