import topPage1 from './topPage1/index.js'; 
import topPage2 from "./topPage2/index.js";
import topPage3 from "./topPage3/index.js";
import topPage4 from './topPage4/index.js';

import about1Block from "./about1/index.js";
import about2Block from './about2/index.js'; 

import bigNumber1 from './bigNumber1/index.js';
import bigNumber2 from './bigNumber2/index.js';
import bigNumber3 from './bigNumber3/index.js';
import bigNumber4 from './bigNumber4/index.js';
import bigNumber5 from './bigNumber5/index.js';
import bigNumber6 from './bigNumber6/index.js';
import bigNumber7 from './bigNumber7/index.js';
import bigNumber8 from './bigNumber8/index.js';
import bigNumber9 from './bigNumber9/index.js';
import bigNumber10 from './bigNumber10/index.js';
import bigNumber11 from './bigNumber11/index.js';

import testimony1 from "./testimony1/index.js";
import testimony2 from './testimony2/index.js';
import testimony3 from './testimony3/index.js';
import testimony4 from './testimony4/index.js';
import testimony5 from './testimony5/index.js';

import prints1 from './prints1/index.js';
import prints2 from './prints2/index.js';

import marketing1 from './marketing1/index.js';
import marketing2 from './marketing2/index.js';

import supportCenter1 from './supportCenter1/index.js';
import supportCenter2 from './supportCenter2/index.js';

import serviceInfo1 from './serviceInfo1/index.js';
import serviceInfo2 from './serviceInfo2/index.js';
import serviceInfo3 from './serviceInfo3/index.js';
import serviceInfo4 from './serviceInfo4/index.js';
import serviceInfo5 from './serviceInfo5/index.js';

import legalSupport from './legalSupport/index.js';

import events1 from './events1/index.js';
import events2 from './events2/index.js';

import products1 from './products1/index.js';
import products2 from './products2/index.js';
import products3 from './products3/index.js';
import products4 from './products4/index.js';

import sideVideo from './sideVideo/index.js';
import centralVideo from './centralVideo/index.js';
import videoFull from './videoFull/index.js';

import stamps1 from './stamps1/index.js';
import stamps2 from './stamps2/index.js';

import partners1 from './partners1/index.js';

import timeLine1 from './timeLine1/index.js';
import timeLine2 from './timeLine2/index.js';
import timeLine3 from './timeLine3/index.js';
import timeLine4 from './timeLine4/index.js';
import timeLine5 from './timeLine5/index.js';
import timeLine6 from './timeLine6/index.js';
import timeLine7 from './timeLine7/index.js';

import faq1 from './faq1/index.js';
import faq2 from './faq2/index.js';
import faq3 from './faq3/index.js';
import faq4 from './faq4/index.js';

import locationUnits1 from './locationUnits1/index.js';
import locationUnits2 from './locationUnits2/index.js';

import customerPortifolio1 from './customerPortifolio1/index.js';
import customerPortifolio2 from './customerPortifolio2/index.js';

import businessModel1 from './businessModel1/index.js';
import businessModel2 from './businessModel2/index.js';
import businessModel3 from './businessModel3/index.js';

import differences1 from './differences1/index.js';
import differences2 from './differences2/index.js';
import differences3 from './differences3/index.js';
import differences4 from './differences4/index.js';
import differences5 from './differences5/index.js';

import formBottom1 from './formBottom1/index.js';
import formBottom2 from './formBottom2/index.js';

import footer from './footer/index.js';

export default (editor, opts) => {
    topPage1(editor, opts)
    topPage2(editor, opts)
    topPage3(editor, opts)
    topPage4(editor, opts)
    
    about1Block(editor, opts)
    about2Block(editor, opts) 

    bigNumber1(editor, opts)
    bigNumber2(editor, opts)
    bigNumber3(editor, opts)
    bigNumber4(editor, opts)
    bigNumber5(editor, opts)
    bigNumber6(editor, opts)
    bigNumber7(editor, opts)
    bigNumber8(editor, opts)
    bigNumber9(editor, opts)
    bigNumber10(editor, opts)
    bigNumber11(editor, opts)

    testimony1(editor, opts)
    testimony2(editor, opts)
    testimony3(editor, opts)
    testimony4(editor, opts)
    testimony5(editor, opts)

    prints1(editor, opts)
    prints2(editor,opts)

    marketing1(editor, opts)
    marketing2(editor, opts)

    supportCenter1(editor, opts)
    supportCenter2(editor, opts)

    serviceInfo1(editor, opts)
    serviceInfo2(editor, opts)
    serviceInfo3(editor, opts)
    serviceInfo4(editor, opts)
    serviceInfo5(editor, opts)

    legalSupport(editor, opts)

    events1(editor, opts)
    events2(editor, opts)

    products1(editor, opts)
    products2(editor, opts)
    products3(editor, opts)
    products4(editor, opts)

    sideVideo(editor, opts)
    centralVideo(editor, opts)
    videoFull(editor, opts)

    stamps1(editor, opts)
    stamps2(editor, opts)

    partners1(editor, opts)

    timeLine1(editor, opts)
    timeLine2(editor, opts)
    timeLine3(editor, opts)
    timeLine4(editor, opts)
    timeLine5(editor, opts)
    timeLine6(editor, opts)
    timeLine7(editor, opts)

    faq1(editor, opts)
    faq2(editor, opts)
    faq3(editor, opts)
    faq4(editor, opts)

    locationUnits1(editor, opts)
    locationUnits2(editor, opts)

    customerPortifolio1(editor, opts)
    customerPortifolio2(editor, opts)

    businessModel1(editor, opts)
    businessModel2(editor, opts)
    businessModel3(editor, opts)
    
    differences1(editor, opts)
    differences2(editor, opts)
    differences3(editor, opts)
    differences4(editor, opts)
    differences5(editor, opts)

    formBottom1(editor, opts)
    formBottom2(editor, opts)

    footer(editor, opts)
}