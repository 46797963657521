export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              type:'container',
              name: 'Container Serviços',
              attributes: { class: 'container d-flex d-sm-column margin-top-50' },
              components: [
                {
                  type: 'rowCardService3',
                },
              ],
              styles: `
             .container{
                max-width: 1600px; 
             }
             .d-flex{
              display:flex;
             }
             .margin-top-50{
              margin-top:50px; 
             }
      
             @media (max-width:992px){
              .d-sm-column{
                flex-direction:column; 
              }
             }
            `,
            },
          },
          view: {
      
          },
};
  return component; 
};