export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname:'div', 
              name: "Div",
              attributes: { class: 'div-emphasis' },
              components: [
                {
                  type:'text',
                  content:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
                  attributes:{class:'text-emphasis'},
                },
              ],
              styles: `
               .div-emphasis{
                width:100%; 
                padding: 25px; 
                background:#F6F7FA; 
                border-left: 10px solid #95A1BB; 
                margin-top:30px; 
                margin-bottom:30px;
               }
               .text-emphasis{
                font-size:16px;
                color:#6E6E6E;
               }
            `,
             },
          },
          view: {
      
          },
    };
  
    return component;
  };
  