import block from "./block.js";
import component from "./components/index.js"
import cardServicesLeft5 from "./components/cardServicesLeft5.js";
import colCardServiceC5 from "./components/colCardServiceC5.js";
import colCardServiceR5 from "./components/colCardServiceR5.js";
import colServiceL5 from "./components/colServiceL5.js";
import containerService5 from "./components/containerService5.js";
import rowCardService5 from "./components/rowCardService5.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("service5", component(opts));
  editor.DomComponents.addType("cardServicesLeft5", cardServicesLeft5(opts));
  editor.DomComponents.addType("colCardServiceC5", colCardServiceC5(opts));
  editor.DomComponents.addType("colCardServiceR5", colCardServiceR5(opts));
  editor.DomComponents.addType("colServiceL5", colServiceL5(opts));
  editor.DomComponents.addType("containerService5", containerService5(opts));
  editor.DomComponents.addType("rowCardService5", rowCardService5(opts));

    //block
  editor.BlockManager.add("service5", block(opts));
};