import block from "./block.js";
import component from "./components/index.js"
import containerUnits1 from "./components/containerUnits1.js";
import imageLocation1 from "./components/imageLocation1.js";
import units1 from "./components/units1.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("locationUnits1", component(opts));
  editor.DomComponents.addType("containerUnits1", containerUnits1(opts));
  editor.DomComponents.addType("imageLocation1", imageLocation1(opts));
  editor.DomComponents.addType("units1", units1(opts));

    //block
  editor.BlockManager.add("locationUnits1", block(opts));
};