export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              name: 'Coluna Selos',
              attributes: { class: 'stamps-container-1' },
              components: [
               {
                type: 'rowStamps1'
               },
               {
                type: 'rowStamps1'
               },
              ],
              styles: `
               .stamps-container-1{
                 width:100%; 
                 margin-top:34px;
               }
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};