import block from "./block.js";
import component from "./components/index.js";
import columnCall2 from "./components/columnCall2.js";
import columnImageForm2 from "./components/columnImageForm2.js";
import columns2 from "./components/columns2.js";
import divForm2 from "./components/divForm2.js";
import scrollDown2 from "./components/scrollDown2.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("topPage2", component(opts));
  editor.DomComponents.addType("columnCall2", columnCall2(opts));
  editor.DomComponents.addType("columnImageForm2", columnImageForm2(opts));
  editor.DomComponents.addType("columns2", columns2(opts));
  editor.DomComponents.addType("divForm2", divForm2(opts));
  editor.DomComponents.addType("scrollDown2", scrollDown2(opts));

    //block
  editor.BlockManager.add("topPage2", block(opts));
};