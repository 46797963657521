export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              type:'div',
              name: 'Div',
              attributes: { class: 'scroll-down' },
              components: [
                {
                  type: 'link',
                  components: [
                    {
                      type:'image',
                      attributes:{class:'arrow-down'},
                    },
                  ]
                }, 
              ],
              styles: `
              .scroll-down{
                position:absolute;
                bottom:50px;
                left:50%;
                z-index:99; 
              }
              .arrow-down{
                width:20px;
                height: 20px; 
              }
      
              @media (max-width:992px){
              .scroll-down{
                left:48%;
              }
              }
            `,
            },
          },
          view: {
      
          },
    };
  
    return component;
  };
  