export default (opts = {}) => {
    const block = {
      category: "Tipografia",
      id: "textBlock",
      label: "Texto",
      fromPlugin: 'basic',
      media:'<svg viewBox="0 0 24 24"> <path fill="currentColor" d="M21,6V8H3V6H21M3,18H12V16H3V18M3,13H21V11H3V13Z"></path></svg>',
      content: { type: "textBlock" },
    };
  
    return block;
  };
  