export default (opts = {}) => {
  const component = {
      model: {
        defaults: {
          tagname: 'div',
          name: 'Coluna Topo',
          attributes: { class: 'column column-50 aling-item-xl-end aling-item-md-center' },
          components: [
            {
              type: 'image',
              attributes: { class: 'featured-image', src: 'https://placehold.co/953x1083' }
            },
          ],
          styles: `
          .featured-image{
            width:100%; 
            height:100%; 
            object-fit: cover;
           }
          .aling-item-xl-end{
            align-items: end;
          }
          .p-20{
            padding:20px;
          }
          
          @media (max-width:992px){
            .aling-item-md-center{
              align-items: center !important;
            }
          }
        `,
        },
      },
      view: {
  
      },
    }; 
    return component; 
}; 