import block from "./block.js";
import component from "./components/index.js"
import containerTimeLine3 from "./components/containerTimeLine3.js";
import itemTimeLine3 from "./components/itemTimeLine3.js";
import rowTimeLine3 from "./components/rowTimeLine3.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("timeLine3", component(opts));
  editor.DomComponents.addType("containerTimeLine3", containerTimeLine3(opts));
  editor.DomComponents.addType("itemTimeLine3", itemTimeLine3(opts));
  editor.DomComponents.addType("rowTimeLine3", rowTimeLine3(opts));

  //block
  editor.BlockManager.add("timeLine3", block(opts));
};