import block from "./block.js";
import component from "./components/index.js"
import columnImageMarketing2 from "./components/columnImageMarketing2.js";
import columnMarketing2 from "./components/columnMarketing2.js";
import columnsMarketing2 from "./components/columnsMarketing2.js";
import containerMarketing2 from "./components/containerMarketing2.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("marketing2", component(opts));
  editor.DomComponents.addType("columnImageMarketing2", columnImageMarketing2(opts));
  editor.DomComponents.addType("columnMarketing2", columnMarketing2(opts));
  editor.DomComponents.addType("columnsMarketing2", columnsMarketing2(opts));
  editor.DomComponents.addType("containerMarketing2", containerMarketing2(opts));

    //block
  editor.BlockManager.add("marketing2", block(opts));
};