export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname:'div', 
              name: "Div",
              attributes: { class: 'row-support-2' },
              components: [      
                {
                  type:'supportItem2',
                },
                {
                  type:'supportItem2',
                },
                {
                  type:'supportItem2',
                },
                {
                  type:'supportItem2',
                },
              ],
              styles: `
              .row-support-2{
                display:flex;
                gap: 30px;
              }
              @media (max-width:992px){
                .row-support-2{
                  flex-direction:column;
                  gap: 20px;
                }
              }
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};