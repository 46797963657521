export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname:'div', 
              name: "Item Suporte",
              attributes: { class: 'item-support-2' },
              components: [      
                {
                  type:'text', 
                  attributes: {class:'dot'},
                }, 

                {
                  type: 'text',
                  content:'Lorem ipsum dolor sit amet',
                  attributes: {class: 'subtitle-support-2' },
                },
                {
                  type:'image', 
                  tagName:'img', 
                  attributes: {class:'icon-support-2', src: 'https://placehold.co/76x76'}
                },
                {
                  type:'text', 
                  content:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et bibendum risus. Cras faucibus viverra dolor, at vehicula erat tempus vel. ',
                  attributes:{class:'text-support-2'}
                },
              ],
              styles: `
              .item-support-2{
                width: 25%; 
                display:flex;
                flex-direction:column; 
                align-items: center; 
              }
              .dot{
                width:8px; 
                height:8px; 
                background-color:#95A1BB; 
                border-radius:100px; 
              }
              .subtitle-support-2{
                font-size:12px; 
                font-weight:500; 
                color: #95A1BB; 
                text-transform:uppercase; 
                margin: 15px 0; 
              }
              .icon-support-2{
                width: 76px; 
              }
              .text-support-2{
                font-size:16px; 
                color: #363940; 
                margin-top: 15px; 
                text-align:center;
              }
              @media (max-width:992px){
                .item-support-2{
                  width:100%;
                }
              }
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};