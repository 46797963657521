export default (opts = {}) => {
  const component = {
    model: {
      defaults: {
        tagname:'div', 
        name: "Colunas Sobre",
        attributes: { class: 'row-full' },
        components: [
          {
            type:'columnAbout'
        },
        {
            type: 'columnImageAbout'
        },
        ],
        styles: `
       .row-full{
          display: flex; 
          flex-direction:row; 
          width:100vw; 
       }
       @media (max-width:992px){
        .row-full{
          flex-direction:column;
        }
       }
      `,
       },
    },
    view: {

    },
  };

  return component;
};
