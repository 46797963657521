export default (opts = {}) => {
    const block = {
      category: "Outros",
      id: "progressBar",
      label: "Barra de Progresso",
      fromPlugin: 'basic',
      media:'<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="59" height="35" viewBox="0 0 128 128"><image id="progress-bar" width="128" height="128" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAQAAABpN6lAAAAAIGNIUk0AAHomAACAhAAA+gAAAIDoAAB1MAAA6mAAADqYAAAXcJy6UTwAAAACYktHRAAAqo0jMgAAAAlwSFlzAAAAYAAAAGAA8GtCzwAAAAd0SU1FB+cFBBIfK/5TGX0AAAC0SURBVHja7doxCoAwAARBI/n/l7W3sTILZqYUi+VIIepxAAAAAAAAAAAAAH81rqtOaJ11QM0AdUDNAHVAzQB1QG0+L4xRJ33r+dyz/QkwQB1QM0AdUDNAHVCbbzesfl+w+jlk+xNggDqgZoA6oGaAOqBmgDqgZoA6oGaAOqBmgDqg9vo+4O/fCbY/AQaoA2oGqANqBqgDav4TrANqBqgDagaoA2oGqAMAAAAAAAAAAAAAvnIDeQIIY7s7fXgAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjMtMDUtMDRUMTg6MzE6NDIrMDA6MDB9pip4AAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIzLTA1LTA0VDE4OjMxOjQyKzAwOjAwDPuSxAAAACh0RVh0ZGF0ZTp0aW1lc3RhbXAAMjAyMy0wNS0wNFQxODozMTo0MiswMDowMFvusxsAAAAASUVORK5CYII="/></svg>', 
      content: { type: "progressBar" },
    };
  
    return block;
  };
  
