export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              name: "Item Unidades",
              attributes: { class: 'item-units-2' },
              components: [
                {
                    type: 'image', 
                    attributes: { class: 'picture-units-2', src: 'https://placehold.co/457x354'}, 
                },
                {
                    type: 'text',
                    content: 'Unidade',
                    attributes: { class: 'name-units'}
                },  
                {
                    type: 'text',
                    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam ultricies lacinia nisi eget mattis.',
                    attributes: { class: 'desc-units'}
                }                
              ],
              styles: `
              .item-units-2{
                width: 33%; 
                padding: 30px; 
                border: 1px solid #DAE0EA; 
                display: flex;
                flex-direction: column; 
                align-items: center; 
              }
              .picture-units-2{
                width: 100%; 
              }
              .desc-units{
                font-size: 16px; 
                color: #363940; 
                margin-top: 15px; 
                text-align: center;
              }
              .name-units{
                font-size: 25px; 
                color: #363940; 
                margin-top: 20px; 
              }
              @media (max-width: 992px){
                .item-units-2{
                    width: 100%; 
                }
              }
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};