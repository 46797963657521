export default (editor, opts = {}) => {
  const bm = editor.BlockManager;

  bm.add('input', {
    label: 'Input',
    category: 'Formulários',
    content: { type: 'input' },
    media:'<svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="59" height="35" viewBox="0 0 512 512"><path d="M35 166.7c-1.4.3-5.7 2-9.5 3.8-11.5 5.5-20.7 16.7-23.9 29-2.4 8.8-2.4 104.2 0 113C4.8 325 13.9 335.9 26 341.8l7.5 3.7 219 .3c163.3.2 220.4 0 224.5-.8 16.3-3.5 30.9-18.4 34-34.9.6-3.2 1-25.7 1-55 0-41.7-.3-50.6-1.6-55.6-3.2-12.4-12.2-23.3-24.3-29.3l-7.5-3.7-220.5-.1c-121.4-.1-221.7.1-223.1.3zM331 256v60H186.2c-139 0-144.9-.1-148.2-1.9-1.9-1-4.5-3.6-5.7-5.7-2.3-3.9-2.3-4-2.3-52.4s0-48.5 2.3-52.4c1.2-2.1 3.8-4.7 5.7-5.7 3.3-1.8 9.2-1.9 148.2-1.9H331v60zm143-58.2c2.2 1.2 4.4 3.5 5.8 6.2 2.2 4.4 2.2 4.6 2.2 52s0 47.6-2.2 52c-4.3 8.3-1.8 8-64 8H361V196h54.8c51 0 55 .1 58.2 1.8z"/><path d="M68 243.2c-5.4 3.7-7.5 7.3-7.5 12.8 0 5.5 2.1 9.1 7.5 12.7l3.3 2.3h71.4c79 0 75.8.3 80.6-6.4 3.1-4.3 3.1-12.9 0-17.2-4.8-6.7-1.6-6.4-80.6-6.4H71.3l-3.3 2.2zM395.7 233.9c-4.4 1-9.5 5.7-10.8 10-2.3 7.9-1 10.3 13.9 25.5 7.6 7.6 15.1 14.5 16.9 15.2 8.5 3.7 13.8.8 29.7-15.8 12.6-13.1 14-15.4 13.4-21.8-.7-7.1-4.6-11.5-11.6-13.1-5.7-1.2-10.1.8-17.7 8.2-4 3.8-7.5 6.9-8 6.9s-4.3-3.4-8.6-7.5c-4.4-4.3-8.5-7.5-9.7-7.5-1.1 0-2.6-.2-3.4-.4-.7-.2-2.5-.1-4.1.3z"/></svg>',
  });
}
