import block from "./block.js";
import component from "./components/index.js"
import containerForm2 from "./components/containerForm2.js";
export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("formBottom2", component(opts));
  editor.DomComponents.addType("containerForm2", containerForm2(opts));

    //block
  editor.BlockManager.add("formBottom2", block(opts));
};