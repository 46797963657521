export default (opts = {}) => {
  const block = {
    category: "Formulários",
    id: "button",
    label: "Botão",
    fromPlugin: 'basic',
    media:'<svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 100 100"><path d="M2.5 26.5C0 28.9 0 28.9 0 50s0 21.1 2.5 23.5L4.9 76h90.2l2.4-2.5c2.5-2.4 2.5-2.4 2.5-23.5s0-21.1-2.5-23.5L95.1 24H4.9l-2.4 2.5zm92.3 2.7c1.7 1.7 1.7 39.9 0 41.6-1.7 1.7-87.9 1.7-89.6 0-1.7-1.7-1.7-39.9 0-41.6 1.7-1.7 87.9-1.7 89.6 0z"/><path d="M14 50.6v5.5l4.3-.3c3.5-.2 4.3-.7 4.5-2.6.2-1.2-.2-2.2-.8-2.2-.7 0-.8-.6-.1-1.8 1.4-2.6-.4-4.2-4.6-4.2H14v5.6zm6-2.7c0 .5-.7 1.1-1.5 1.5-.9.3-1.5 0-1.5-.9 0-.8.7-1.5 1.5-1.5s1.5.4 1.5.9zm-.2 4.9c.2.7-.3 1.2-1.2 1.2-.9 0-1.6-.7-1.6-1.6 0-1.7 2.2-1.4 2.8.4zM26 49.3c0 4.9 1.3 6.7 5 6.7s5-1.8 5-6.7c0-3.1-.4-4.3-1.4-4.3-1 0-1.6 1.4-1.8 4.2-.2 3.1-.8 4.4-2 4.6-1.5.3-1.8-.4-1.8-4.2 0-3.4-.4-4.6-1.5-4.6S26 46.1 26 49.3zM39 46c0 .5.7 1 1.5 1 1.1 0 1.5 1.2 1.5 4.5s.4 4.5 1.5 4.5 1.5-1.2 1.5-4.5.4-4.5 1.5-4.5c.8 0 1.5-.5 1.5-1 0-.6-2-1-4.5-1s-4.5.4-4.5 1zM50 46c0 .5.7 1 1.5 1 1.1 0 1.5 1.2 1.5 4.5s.4 4.5 1.5 4.5 1.5-1.2 1.5-4.5c0-3.8.3-4.5 2-4.5 1.1 0 2-.5 2-1 0-.6-2.2-1-5-1-2.7 0-5 .4-5 1zM63.2 46.2c-1.7 1.7-1.5 7.3.2 8.7 2.4 2 6.9.9 8.5-2 1.3-2.4 1.3-2.9-.2-5.3-1.9-2.8-6.3-3.6-8.5-1.4zm5.8 2.3c1.4 1.7.4 5.5-1.5 5.5-2.1 0-3.5-3.1-2.4-5.2 1.2-2.2 2.3-2.3 3.9-.3zM76 50.5c0 3 .5 5.5 1 5.5.6 0 1-1.5 1-3.3v-3.2l2.6 3.2c1.4 1.8 3 3.3 3.5 3.3s.9-2.5.9-5.5-.4-5.5-1-5.5c-.5 0-1 1.5-1 3.2l-.1 3.3-2.7-3.3c-1.5-1.8-3-3.2-3.4-3.2-.5 0-.8 2.5-.8 5.5z"/></svg>',
    content: { type: "button" },
  };

  return block;
};
