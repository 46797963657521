export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              type:'div', 
              name: "Card Diferenciais",
              attributes: { class:'card-differences-5'},
              components: [
                {
                  type: 'image', 
                  attributes: { class: 'icon-differences-5', src: 'https://placehold.co/60x60'}, 
                }, 
                {
                  type: 'text', 
                  content: 'Lorem ipsum dolor ', 
                  attributes: { class: 'title-differences-5 margin-left-15'},
                },
              ],
              styles: `
              .card-differences-5{
                width: 47%; 
                display: flex; 
                align-items: center; 
              }
              .icon-differences-5{
                width: 60px; 
              }
              .margin-left-15{
                margin-left: 15px; 
              }
              .title-differences-5{ 
                font-size: 18px; 
                color: #363940; 
                margin-top: 15px; 
              }
              @media (max-width: 992px){
                .card-differences-5{
                  width: 100%; 
                }
              }
           `,
             },
          },
          view: {
      
          },
};
  return component; 
};