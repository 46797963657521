export default (opts = {}) => {
    const component = {
        model: { 
            defaults: {
              name: "Contador",
              attributes: { class:'counter-container-9'},
              components: [
                {
                    type: 'text',
                    content: 'Lorem ipsum dolor sit amet ',
                    attributes: { class: 'context' }
                  },
                {
                  tagName: 'div',
                  attributes: { class: 'counters-9' },
                  components: [
                    {
                        type: 'text',
                        content: '+',
                        attributes: { class: 'prefix font-40'},
                      },
                    {
                      type:'counters',
                      attributes:{class:'counter-value font-40'},
                    },
                    {
                        type: 'text',
                        content: 'Lorem Ipsum',
                        attributes: { class: 'sufix font-40'},
                      },
                   
                  ],
                },
              ],
              styles: `
              .context{
                color:#363940; 
                font-size: 16px; 
              }
              .sufix{
                color:#363940; 
                margin-left:8px; 
              }
              .prefix, .counter-value{
                color:#363940; 
              }
              .counters-9{
                display:flex;
                aling-items:center;
                margin-top: 15px; 
              }
              .font-40{
                font-size:40px;
              }
              .counter-container-9{
                padding: 20px; 
                background: #E6E6E6; 
                max-width: 48%; 
              }

              @media (max-width: 992px){
                .counter-container-9{
                  max-width: 100%; 
                }
              }
           `,
            },
          },
          view: {
      
          },
};
  return component; 
};