export default (opts = {}) => {
  const component = {
    model: {
      defaults: {
        tagName:'div',
        components: [
          {
            tagName: 'h1',
            type:'text',
            name:'Titulo',
            components: 'Titulo',
            traits: [
              {
                type: "select",
                options: [
                  { value: "h1", name: opts.labelN1 },
                  { value: "h2", name: opts.labelN2 },
                  { value: "h3", name: opts.labelN3 },
                  { value: "h4", name: opts.labelN4 },
                  { value: "h5", name: opts.labelN5 },
                  { value: "h6", name: opts.labelN6 },
                ],
                label: 'Tamanho',
                name: "tagName",
                changeProp: 1,
              },
              {
                type:'id',
                name:'id',
                Label:'id',
              }
            ],
          },
        ],
         styles: `
      `,
      },
    },
    view: {

    },
  };

  return component;
};
