export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
                tagname: 'div',
                name: 'Div Parceiros',
                attributes: { class: 'w-100 d-flex d-sm-column gap-50' },
                components: [
                    {
                        type: 'div',
                        attributes: { class: 'w-50 w-sm-100 position-relative d-column justify-center' },
                        components: [
                            {
                                type: 'image',
                                attributes: { class: 'w-100', src: 'https://placehold.co/674x446' },
                            },
                        ]
                    },
                    {
                        type: 'div',
                        attributes: { class: 'w-50 w-sm-100 position-relative d-column justify-center' },
                        components: [
                            {
                                type: 'text',
                                content: 'Subtítulo',
                                name: 'Subtitulo',
                                attributes: { class: 'subtitle text-uppercase' },
                            },
                            {
                                tagName: 'h2',
                                type: 'text',
                                name: 'Titulo',
                                components: 'Parceiros',
                                attributes: { class: 'principal-title' },
                                traits: [
                                    {
                                        type: "select",
                                        options: [
                                            { value: "h1", name: opts.labelN1 },
                                            { value: "h2", name: opts.labelN2 },
                                            { value: "h3", name: opts.labelN3 },
                                            { value: "h4", name: opts.labelN4 },
                                            { value: "h5", name: opts.labelN5 },
                                            { value: "h6", name: opts.labelN6 },
                                        ],
                                        label: 'Tamanho',
                                        name: "tagName",
                                        changeProp: 1,
                                    },
                                    {
                                        type: 'id',
                                        name: 'id',
                                        Label: 'id',
                                    }
                                ],
                            },
                            {
                                type: 'text',
                                content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et bibendum risus. Cras faucibus viverra dolor, at  ',
                                attributes: { class: 'body-text' },
                            },
                            {
                                type: 'div', 
                                attributes: { class: 'row-patners'}, 
                                components: [
                                    {
                                        type: 'image', 
                                        attributes: { class: 'logo-patners', src: 'https://placehold.co/205x35'}
                                    },
                                    {
                                        type: 'image', 
                                        attributes: { class: 'logo-patners', src: 'https://placehold.co/205x35'}
                                    },
                                    {
                                        type: 'image', 
                                        attributes: { class: 'logo-patners', src: 'https://placehold.co/205x35'}
                                    },
                                    {
                                        type: 'image', 
                                        attributes: { class: 'logo-patners', src: 'https://placehold.co/205x35'}
                                    },
                                    {
                                        type: 'image', 
                                        attributes: { class: 'logo-patners', src: 'https://placehold.co/205x35'}
                                    },
                                    {
                                        type: 'image', 
                                        attributes: { class: 'logo-patners', src: 'https://placehold.co/205x35'}
                                    },
                                    {
                                        type: 'image', 
                                        attributes: { class: 'logo-patners', src: 'https://placehold.co/205x35'}
                                    },
                                    {
                                        type: 'image', 
                                        attributes: { class: 'logo-patners', src: 'https://placehold.co/205x35'}
                                    },
                                    {
                                        type: 'image', 
                                        attributes: { class: 'logo-patners', src: 'https://placehold.co/205x35'}
                                    }
                                ]
                            }

                        ],
                    },

                ],
                styles: `
                .w-100{
                  width: 100%; 
                }
                .d-flex{
                  display:flex; 
                }
                .gap-50{
                  gap:50px; 
                }
                .w-50{
                  width: 50%; 
                }
                .position-relative{
                  position:relative
                }
                .position-absolute{
                  position: absolute; 
                }
            
                .d-column{
                    display: flex; 
                    flex-direction: column;
                }
                .justify-center{
                    justify-content: center;
                }
                .principal-title{
                    font-size: 30px; 
                    color: #363940; 
                    margin:0;
                   }
                .body-text{
                    font-size: 16px; 
                    color: #363940; 
                    margin-top:30px;
                   }
                .subtitle{
                    font-size: 13px; 
                    color: #747474; 
                   }
                .text-uppercase{
                    text-transform: uppercase;
                   }
                .row-patners{
                    width: 100%; 
                    display: flex; 
                    gap: 16px; 
                    flex-wrap: wrap; 
                    margin-top: 50px;
                }
                .logo-patners{
                    width: 31%; 
                }

                @media (max-width:992px){
                  .d-sm-column{
                    flex-direction:column; 
                  }
                  .w-sm-100{
                    width: 100% !important; 
                  }
                  .logo-patners{
                    width: 100%; 
                }
                .row-patners{
                    flex-direction: column;
                }

                }
            `,
            },
        },
        view: {

        },
    };
    return component;
};