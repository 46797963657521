import block from "./block.js";
import component from "./components/index.js"
import containerTestimony3 from "./components/containerTestimony3.js";
import itemTestimony3 from "./components/itemTestimony3.js";
import rowTestimony3 from "./components/rowTestimony3.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("testimony3", component(opts));
  editor.DomComponents.addType("containerTestimony3", containerTestimony3(opts));
  editor.DomComponents.addType("itemTestimony3", itemTestimony3(opts));
  editor.DomComponents.addType("rowTestimony3", rowTestimony3(opts));

  //block
  editor.BlockManager.add("testimony3", block(opts));
};