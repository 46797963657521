export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname:'div', 
              name: "Div",
              attributes: { class: 'legal-support' },
              components: [      
                {
                  type:'rowLegalSupport',
                },
                {
                  type:'rowLegalSupport',
                },
              ],
              styles: `
               .legal-support{
                display: flex; 
                flex-direction:column;
                gap:20px; 
                margin-top:50px;
               }
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};