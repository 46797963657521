const traitInputAttr = { placeholder: 'ex: Insira o texto' };

export default {
  pt: {
    assetManager: {
      addButton: "Adicionar imagem",
      inputPlh: "http://caminho/para/a/imagem.jpg",
      modalTitle: "Selecionar imagem",
      uploadTitle: "Solte os arquivos aqui ou clique para enviar",
    },
    // Here just as a reference, GrapesJS core doesn't contain any block,
    // so this should be omitted from other local files
    blockManager: {
      labels: {
        // 'block-id': 'Block Label',
      },
      categories: {
        // 'category-id': 'Category Label',
      },
    },
    domComponents: {
      names: {
        "": "Box",
        wrapper: "Corpo",
        text: "Texto",
        comment: "Comentário",
        image: "Imagem",
        video: "Vídeo",
        label: "Label",
        link: "Link",
        map: "Mapa",
        tfoot: "Rodapé da tabela",
        tbody: "Corpo da tabela",
        thead: "Cabeçalho da tabela",
        table: "Tabela",
        row: "Linha da tabela",
        cell: "Célula da tabela",
        section: "Seção",
        body: "Corpo",
      },
    },
    deviceManager: {
      device: "Dispositivo",
      devices: {
        desktop: "Desktop",
        tablet: "Tablet",
        mobileLandscape: "Celular, modo panorâmico",
        mobilePortrait: "Celular, modo retrato",
      },
    },
    styleManager: {
      empty: "Selecione um elemento para usar o gerenciador de estilos",
      layer: "Camada",
      fileButton: "Imagens",
      sectors: {   
        general: "Geral",
        typography: "Tipografia",
        flex: "Posicionamento",
        dimension: "Dimensão",
        decorations: "Aparência",
        extra: "Efeito",
        flex: "Flex",
        dimension: "Dimensão",
      },
      properties: {
        display: "Exibição",
        float: "Flutuar",
        position: "Posição",
        "font-family": "Fonte",
        top: "Superior",
        right: "Direita",
        left: "Esquerda",
        bottom: "Inferior",
        "flex-direction": "Direção",
        "justify-content": "Justificar",
        "align-content": "Alinhar conteúdo",
        "flex-basis": "Tamanho base flexível",
        "flex-shrink": "Redução flexível",
        "flex-wrap": "Quebra de linha",
        "align-items": "Alinhar item",
        order: "Ordem",
        "flex-grow": "Expansão flexível",
        "align-self": "Alinhamento vertical",
        width: "Largura",
        height: "Altura",
        "max-width": "Largura máxima",
        "min-height": "Altura mínima",
        margin: "Margem",
        padding: "Espaçamento interno",
        "font-size": "Tamanho",
        "font-weight": "Peso",
        "letter-spacing": "Espaçamento",
        color: "Cor",
        "line-height": "Altura da linha",
        "text-align": "Alinhamento do texto",
        "text-shadow": "Sombra de texto",
        "background-color": "Preenchimento",
        "border-radius": "Bordas arredondadas",
        "border-top-left-radius-sub": "Superior esquerdo",
        "border-top-right-radius-sub": "Superior direito",
        "border-bottom-left-radius-sub": "Inferior esquerdo",
        "border-bottom-right-radius-sub": "Inferior direito",
        border: "Borda",
        "border-width-sub": "Largura",
        "border-style-sub": "Estilo",
        "border-color-sub": "Cor da borda",
        "box-shadow": "Sombra",
        background: "Fundo do elemento",
        opacity: "Opacidade",
        transition: "Transição",
        transform: "Transformação",
        "transition-property-sub": "Propriedade",
        "transition-duration-sub": "Duração",
        "transition-timing-function-sub": "Tempo",
        "transform-value": "Rotação",
      },
      options: {
        display: {
          block: "bloco",
          inline: "em linha",
          "inline-block": "bloco em linha",
          none: "nenhum",
        },
        float: {
          none: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"style="width: 14px; height: 20px; fill: #fff;"><path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z"/></svg>',
          left: '<svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" style="width: 20px; height: 20px; fill: #fff;"><path d="m17 17.75c0-.414-.336-.75-.75-.75h-13.5c-.414 0-.75.336-.75.75s.336.75.75.75h13.5c.414 0 .75-.336.75-.75zm5-4c0-.414-.336-.75-.75-.75h-18.5c-.414 0-.75.336-.75.75s.336.75.75.75h18.5c.414 0 .75-.336.75-.75zm-9-4c0-.414-.336-.75-.75-.75h-9.5c-.414 0-.75.336-.75.75s.336.75.75.75h9.5c.414 0 .75-.336.75-.75zm7-4c0-.414-.336-.75-.75-.75h-16.5c-.414 0-.75.336-.75.75s.336.75.75.75h16.5c.414 0 .75-.336.75-.75z" fill-rule="nonzero"/></svg>',
          right:
            '<svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"style="width: 20px; height: 20px; fill: #fff;"><path d="m7 17.75c0-.414.336-.75.75-.75h13.5c.414 0 .75.336.75.75s-.336.75-.75.75h-13.5c-.414 0-.75-.336-.75-.75zm-5-4c0-.414.336-.75.75-.75h18.5c.414 0 .75.336.75.75s-.336.75-.75.75h-18.5c-.414 0-.75-.336-.75-.75zm9-4c0-.414.336-.75.75-.75h9.5c.414 0 .75.336.75.75s-.336.75-.75.75h-9.5c-.414 0-.75-.336-.75-.75zm-7-4c0-.414.336-.75.75-.75h16.5c.414 0 .75.336.75.75s-.336.75-.75.75h-16.5c-.414 0-.75-.336-.75-.75z" fill-rule="nonzero"/></svg>',
        },
        position: {
          static: "estático",
          relative: "relativo",
          absolute: "flutuante",
          fixed: "fixo",
        },
        "flex-direction": {
          row: "linha",
          "row-reverse": "linha-reversa",
          column: "coluna",
          "column-reverse": "coluna-reversa",
        },
        "justify-content": {
          "flex-start": "início",
          "flex-end": "final",
          center: "centro",
          "space-between": "entre-espaços",
          "space-around": "espaço-ao-redor",
          "space-evenly": "uniforme",
        },
        "align-content": {
          "flex-start": "início",
          "flex-end": "final",
          center: "centro",
          "space-between": "entre-espaços",
          "space-around": "espaço-ao-redor",
          stretch: "esticar",
        },
        "flex-wrap": {
          nowrap: "manter",
          wrap: "quebrar",
          "wrap-reverse": "querbra-reversa",
        },
        "align-items": {
          "flex-start": "início",
          "flex-end": "final",
          center: "centro",
          baseline: "linha base",
          stretch: "esticar",
        },
        "align-self": {
          "flex-start": "início",
          "flex-end": "final",
          center: "centro",
          baseline: "linha base",
          stretch: "total",
        },
        "text-align": {
          left: '<svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" style="width: 20px; height: 20px; fill: #fff;"><path d="m17 17.75c0-.414-.336-.75-.75-.75h-13.5c-.414 0-.75.336-.75.75s.336.75.75.75h13.5c.414 0 .75-.336.75-.75zm5-4c0-.414-.336-.75-.75-.75h-18.5c-.414 0-.75.336-.75.75s.336.75.75.75h18.5c.414 0 .75-.336.75-.75zm-9-4c0-.414-.336-.75-.75-.75h-9.5c-.414 0-.75.336-.75.75s.336.75.75.75h9.5c.414 0 .75-.336.75-.75zm7-4c0-.414-.336-.75-.75-.75h-16.5c-.414 0-.75.336-.75.75s.336.75.75.75h16.5c.414 0 .75-.336.75-.75z" fill-rule="nonzero"/></svg>',
          center:
            '<svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"style="width: 20px; height: 20px; fill: #fff;"><path d="m6 17.75c0-.414.336-.75.75-.75h10.5c.414 0 .75.336.75.75s-.336.75-.75.75h-10.5c-.414 0-.75-.336-.75-.75zm-4-4c0-.414.336-.75.75-.75h18.5c.414 0 .75.336.75.75s-.336.75-.75.75h-18.5c-.414 0-.75-.336-.75-.75zm0-4c0-.414.336-.75.75-.75h18.5c.414 0 .75.336.75.75s-.336.75-.75.75h-18.5c-.414 0-.75-.336-.75-.75zm4-4c0-.414.336-.75.75-.75h10.5c.414 0 .75.336.75.75s-.336.75-.75.75h-10.5c-.414 0-.75-.336-.75-.75z" fill-rule="nonzero"/></svg>',
          right:
            '<svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"style="width: 20px; height: 20px; fill: #fff;"><path d="m7 17.75c0-.414.336-.75.75-.75h13.5c.414 0 .75.336.75.75s-.336.75-.75.75h-13.5c-.414 0-.75-.336-.75-.75zm-5-4c0-.414.336-.75.75-.75h18.5c.414 0 .75.336.75.75s-.336.75-.75.75h-18.5c-.414 0-.75-.336-.75-.75zm9-4c0-.414.336-.75.75-.75h9.5c.414 0 .75.336.75.75s-.336.75-.75.75h-9.5c-.414 0-.75-.336-.75-.75zm-7-4c0-.414.336-.75.75-.75h16.5c.414 0 .75.336.75.75s-.336.75-.75.75h-16.5c-.414 0-.75-.336-.75-.75z" fill-rule="nonzero"/></svg>',
          justify:
            '<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"style="width: 18px; height: 18px; fill: #fff;"><path d="M24 21h-24v-2h24v2zm0-4.024h-24v-2h24v2zm0-3.976h-24v-2h24v2zm0-4h-24v-2h24v2zm0-6v2h-24v-2h24z"/></svg>',
        },
        "border-style-sub": {
          solid: "sólido",
          dotted: "pontilhada",
          dashed: "tracejada",
          double: "dupla",
          groove: "entalhada",
          ridge: "elevada",
          inset: "interna",
          outset: "externa",
        },
        "transition-property-sub": {
          all: "todos",
          width: "largura",
          height: "altura",
          "background-color": "cor de fundo",
          transform: "tranformação",
          "box-shadow": "sombra",
          opacity: "opacidade",
        },
        "transition-timing-function-sub": {
          linear: "constante",
          ease: "suave",
          "ease-in": "suave no início",
          "ease-out": "suave no final",
          "ease-in-out": "suave no início e no fim",
        },
      },
    },
    traitManager: {
      empty: "Selecione um elemento para usar o gerenciador de características",
      label: "Configurações do componente",
      traits: {
        settings: "Configurações",
        // The core library generates the name by their `name` property
        labels: {
          // id: 'Id',
          // alt: 'Alt',
          // title: 'Title',
          // href: 'Href',
        },
        // In a simple trait, like text input, these are used on input attributes
        attributes: {
          id: traitInputAttr,
          alt: traitInputAttr,
          title: traitInputAttr,
          href: { placeholder: "ex: https://google.com" },
        },
        // In a trait like select, these are used to translate option names
        options: {
          target: {
            false: "Esta janela",
            _blank: "Nova janela",
          },
        },
      },
    },
    selectorManager: {
      label: "Classes",
      selected: "Selecionado",
      emptyState: "- Estado -",
      states: {
        hover: "Hover",
        active: "Click",
        "nth-of-type(2n)": "Even/Odd",
      },
    },
    panels: {
      buttons: {
        titles: {
          preview: "Pré-visualização",
          fullscreen: "Tela cheia",
          "sw-visibility": "Ver componentes",
          "export-template": "Ver código",
          "open-sm": "Abrir gerenciador de estilos",
          "open-tm": "Configurações",
          "open-layers": "Abrir gerenciador de camadas",
          "open-blocks": "Abrir blocos",
        },
      },
    },
  },
};
