export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname: 'div',
              name: "Coluna Modelos",
              attributes: { class: 'column column-60 position-relative p-20' },
              components: [
                {
                  type: 'rowModelBusiness1',
                },
      
              ],
              styles: `
             .column{
              display:flex; 
              flex-direction:column;
              align-items: flex-start;
             }
             .column-60{
              width:60%; 
             }
             .column-40{
              width:40%; 
             }
             @media (max-width:992px){
              .column-60{
                width:100%; 
              }
              .column-40{
                width:100%; 
              }
             }
            `,
            },
          },
          view: {
      
          },
};
  return component; 
};