export default (opts = {}) => {
    const component = {
        model: { 
            defaults: {
              name: "Contador",
              attributes: { class:'counter-container-5'},
              components: [
                {
                  tagName: 'div',
                  attributes: { class: 'counters-5' },
                  components: [
                    {
                        type: 'text',
                        content: '+',
                        attributes: { class: 'prefix font-30'},
                      },
                    {
                      type:'counters',
                      attributes:{class:'counter-value font-30'},
                    },
                    {
                      type: 'text',
                      content: 'lorem ipsum dolor',
                      attributes: { class: 'sufix font-30'},
                    },
                  ],
                },
                   {
                  type: 'text',
                  content: 'Lorem ipsum dolor sit amet consectetur adipiscing elit donect',
                  attributes: { class: 'context font-16 margin-left-16' }
                },
              ],
              styles: `
              .context{
                color: #363940; 
              }
              .sufix{
                color:#363940; 
                margin-left:8px; 
              }
              .margin-left-16{
                margin-left: 16px;
              }
              .counter-value{
                color: #363940; 
              }
              .prefix{
                color:#363940; 
              }
              .counters-5{
                display:flex;
                justify-content:center; 
                aling-items:center;
                background: #F9F9F9; 
                padding: 20px; 
              }
              .font-30{
                font-size:30px;
              }
              .font-16{
                font-size:16px; 
              }
              .counter-container-5{
                display:flex;
                align-items:center; 
                margin-top:20px; 
              }
           `,
            },
          },
          view: {
      
          },
};
  return component; 
};