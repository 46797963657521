export default (editor, opts = {}) => {
  const domc = editor.DomComponents;

  domc.addType('input', {
    model: {
      defaults: {
        tagName: 'input',
        components:[
         {
          type:'default',
         },
        ],
         styles: `
          .gjs-hovered{
          border:1px solid #00557B;
          position:relative;
         }
         .gjs-hovered:after {
          content: attr(data-gjs-type);
          font-size: 12px;
          color: #fff;
          background: #00557B;
          position: absolute;
          top: 0;
          left: 0;
          padding: 5px;
          text-transform: capitalize;
      }
      `,
      },
    },
    view: {

    },
  });
};
