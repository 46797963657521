export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname:'div',
              name: "Coluna Sobre", 
              attributes: { class: 'column column-50 p-20' },
              components: [
                {
                  type:'text', 
                  name:'Subtitulo',
                  content: 'Subtítulo', 
                  attributes: {class:'subtitle text-uppercase'},
                }, 
                {
                  tagName: 'h2',
                  type:'text',
                  name:'Titulo',
                  components: 'Quem somos',
                  attributes: {class: 'principal-title'}, 
                  traits: [
                    {
                      type: "select",
                      options: [
                        { value: "h1", name: opts.labelN1 },
                        { value: "h2", name: opts.labelN2 },
                        { value: "h3", name: opts.labelN3 },
                        { value: "h4", name: opts.labelN4 },
                        { value: "h5", name: opts.labelN5 },
                        { value: "h6", name: opts.labelN6 },
                      ],
                      label: 'Tamanho',
                      name: "tagName",
                      changeProp: 1,
                    },
                    {
                      type:'id',
                      name:'id',
                      Label:'id',
                    }
                  ],
                },
                {
                  type: 'text',
                  content:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et bibendum risus. Cras faucibus viverra dolor, at  ',
                  attributes: {class: 'body-text'},
                },
                {
                   type: 'emphasis'
                },
                {
                  type: 'checkListAbout'
               },
               {
                type: 'button'
               }
              ],
              styles: `
               .principal-title{
                font-size: 30px; 
                color: #363940;
                margin:0;
               }
               .body-text{
                font-size: 16px; 
                color: #363940; 
                margin-top:30px;
               }
               .subtitle{
                font-size: 13px; 
                color: #747474; 
               }
               .text-uppercase{
                text-transform: uppercase;
               }
            `,
             },
          },
          view: {
      
          },
    };
  
    return component;
  };
  