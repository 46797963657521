import block from "./block.js";
import component from "./components/index.js"
import containerTimeLine6 from "./components/containerTimeLine6.js";
import itemTimeLine6 from "./components/itemTimeLine6.js";
import rowTimeLine6 from "./components/rowTimeLine6.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("timeLine6", component(opts));
  editor.DomComponents.addType("containerTimeLine6", containerTimeLine6(opts));
  editor.DomComponents.addType("itemTimeLine6", itemTimeLine6(opts));
  editor.DomComponents.addType("rowTimeLine6", rowTimeLine6(opts));

  //block
  editor.BlockManager.add("timeLine6", block(opts));
};