export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
            components:[
              {
                type:'text',
                content:'Lorem Ipsum',
              },
            ]
            },
          },
          view: {
      
          },
    };
  
    return component;
  };
  