import block from "./block.js";
import component from "./components/index.js"
import containerNumbers4 from "./components/containerNumbers4.js";
import numbers4 from "./components/numbers4.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("bigNumber4", component(opts));
  editor.DomComponents.addType("containerNumbers4", containerNumbers4(opts));
  editor.DomComponents.addType("numbers4", numbers4(opts));
  
    //block
  editor.BlockManager.add("bigNumber4", block(opts));
};