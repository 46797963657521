export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname: 'div',
              name: 'Formulario',
              attributes: { class: 'div-form-4' },
              components: [
                {
                  type: 'text',
                  content: 'Título do formulário',
                  attributes: { class: 'font-20 text-center title-color margin-bottom-20' },
                },
                {
                  type: "cubosuite-form"
                },
                {
                  type: "text",
                  content: 'Ao responder o presente formulário, declaro, COMO TITULAR dos dados aqui fornecidos, que autorizo a empresa PROSPECTA DIGITALl, aqui denominada CONTROLADORA, disponha dos meus dados pessoais e dados pessoais sensíveis, de acordo com os artigos 7° e 11 da Lei n° 13.709/ 2018. Os dados serão utilizados para fim de contato através do whatsapp, e-mail e telefone.',
                  attributes: { class: 'lgpd-text margin-top-20' }
                }
              ],
              styles: `
              .div-form-4{
                width:60%;
                background: #fff; 
                padding: 20px; 
                display: flex; 
                flex-direction:column;
                justify-content: center;
                aling-content:center;
              }
              .margin-bottom-20{
                margin-bottom:20px; 
              }
              .lgpd-text{
                font-size:8px;
                color: #95A1BB;
                font-weight: 300; 
              }
            .font-20{
              font-size: 20px;
            }
            .text-center{
              text-align:center;
            }
            .form-control{
              background:#F6F7FA; 
              padding:10px; 
              font-size:13px; 
              color: #9A9A9A;
              border:none;
              width:100%;
              margin-top:8px;
            }
            .label{
              font-size:13px;
            }
            .form-group{
              margin-bottom:15px;
            }
            .btn.btn-primary {
              background-color: #95a1bb;
              width: 100%;
              padding: 15px 15px 15px 15px;
              margin: 20px 0 0 0;
              color: white;
              font-size: 15px;
              border: 0 none black;
            }
            @media (max-width:992px){
              .div-form-4{
                width:100%; 
              }
            }
            `,
            },
          },
          view: {
      
          },
};
  return component; 
};