export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
                name: 'Div',
                attributes: { class: 'd-flex gap-16 wrap' },
                components: [
                    {
                        type: 'itemProducts4',
                    },
                    {
                        type: 'itemProducts4',
                    },
                    {
                        type: 'itemProducts4',
                    },
                ],
                styles: `
                .gap-16{
                    gap: 16px; 
                }
                .d-flex{
                    display: flex;
                }
                .wrap{
                    flex-wrap: wrap;
                }
            `,
            },
        },
        view: {

        },
    };
    return component;
};