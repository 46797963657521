export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname:'div', 
              name: "Item Suporte",
              attributes: { class: 'item-legal-support' },
              components: [      
                {
                  type:'headerLegalSuport',
                },
                {
                  type:'text', 
                  content: 'Quidam officiis similique sea ei, vel tollit indoctum efficiendi ei, at nihil tantas platonem eos. ', 
                  attributes: {class:'text-legal'},
                }, 
                {
                  type: 'text',
                  content:'Lorem ipsum dolor sit amet',
                  attributes: {class: 'subtitle-legal'},
                },
              ],
              styles: `
              .item-legal-support{
                width:33%; 
                background:#F6F7FA; 
                padding:30px; 
              }
              .text-legal{
                font-size: 16px;
                color:#999999; 
                margin-top: 20px;
              }
              .subtitle-legal{
                font-size: 16px; 
                color:#333333;
                margin-top:15px; 
              }
              @media (max-width:992px){
                .item-legal-support{
                  width:100%; 
                }
              }
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};