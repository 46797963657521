import block from "./block.js";
import component from "./components/index.js"
import columnsStamp1 from "./components/columnsStamp1.js";
import containerStamps1 from "./components/containerStamps1.js";
import rowStamps1 from "./components/rowStamps1.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("stamps1", component(opts));
  editor.DomComponents.addType("columnsStamp1", columnsStamp1(opts));
  editor.DomComponents.addType("containerStamps1", containerStamps1(opts));
  editor.DomComponents.addType("rowStamps1", rowStamps1(opts));

    //block
  editor.BlockManager.add("stamps1", block(opts));
};