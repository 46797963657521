export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname:'div', 
              name: 'Prints',
              attributes: { class: 'div-item-prints margin-top-50' },
              components: [
                {
                  type: 'itemPrints2'
                },
                {
                  type: 'itemPrints2'
                },
                {
                  type: 'itemPrints2'
                },

              ],
              styles: `
               .div-item-prints{
                display:flex;
                justify-content:center; 
                gap:20px;
               }
               .margin-top-50{
                margin-top:50px;
               }
               @media (max-width:992px){
                .div-item-prints{
                  flex-direction:column;
                  width:100%;
                 }
               }
      
            `,
             },
          },
          view: {
      
          },
    }; 
    return component; 
};