import block from "./block.js";
import component from "./components/index.js"
import containerTimeLine5 from "./components/containerTimeLine5.js";
import itemTimeLine5 from "./components/itemTimeLine5.js";
import rowTimeLine5 from "./components/rowTimeLine5.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("timeLine5", component(opts));
  editor.DomComponents.addType("containerTimeLine5", containerTimeLine5(opts));
  editor.DomComponents.addType("itemTimeLine5", itemTimeLine5(opts));
  editor.DomComponents.addType("rowTimeLine5", rowTimeLine5(opts));

  //block
  editor.BlockManager.add("timeLine5", block(opts));
};