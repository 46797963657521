export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              name: 'Item Tempo',
              attributes: { class: 'item-time-line-6' },
              components: [
                 {
                   type:'image', 
                   tagName:'img',  
                   attributes:{class:'icon-time-line-6', src: 'https://placehold.co/126x126'}, 
                 },
                {
                  type: 'div', 
                  attributes: { class: 'line-dot'},
                  components: [
                    {
                        type: 'div', 
                        attributes: { class: 'dot-6'}
                    },
                    {
                        type: 'div', 
                        attributes: { class: 'line-6'}
                    },
                    {
                        type: 'div', 
                        attributes: { class: 'dot-6'}
                    },
                  ]
                },
                 {
                    type: 'text', 
                    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                    attributes: { class: 'time-text-6'}
                 }
              ],
              styles: `
               .line-6{
                width: 1px; 
                height: 80px; 
                background: #363940; 
               }
              .dot-6{
                width: 6px; 
                height: 6px; 
                background: #363940; 
                border-radius: 100%; 
              }
              .line-dot{
                position: absolute; 
                left: 0; 
                top: 40px; 
                display: flex; 
                flex-direction: column; 
                align-items: center; 
              }
              .icon-time-line-6{
                width: 100%; 
                border-radius: 10px; 
              } 
              .item-time-line-6{
                width: 16%;
                position: relative; 
                padding-left: 20px;
                z-index: 99;
              }
              .time-text-6{
                font-size: 16px; 
                color: #363940; 
                margin-top: 15px; 
              }

              @media (max-width: 992px){
                .item-time-line-6{
                    width: 47%; 
                  }
                .icon-time-line-6{
                    width: 113px; 
                    height: 113px;
                }
              }
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};