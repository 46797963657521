import block from "./block.js";
import component from "./components/index.js"
import containerTestimony2 from "./components/containerTestimony2.js";
import itemTestimony2 from "./components/itemTestimony2.js";
import rowTestimony2 from "./components/rowTestimony2.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("testimony2", component(opts));
  editor.DomComponents.addType("containerTestimony2", containerTestimony2(opts));
  editor.DomComponents.addType("itemTestimony2", itemTestimony2(opts));
  editor.DomComponents.addType("rowTestimony2", rowTestimony2(opts));

  //block
  editor.BlockManager.add("testimony2", block(opts));
};