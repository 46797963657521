import block from "./block.js";
import component from "./components/index.js"
import containerVideoCentral from "./components/containerVideoCentral.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("centralVideo", component(opts));
  editor.DomComponents.addType("containerVideoCentral", containerVideoCentral(opts));

    //block
  editor.BlockManager.add("centralVideo", block(opts));
};