export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname: 'div',
              name: 'Div',
              attributes: { class: 'w-100 border-top h-40 back-image' },
              styles: `
              .border-top{
               border-top:3px solid #707070; 
              }
              .back-image{
              bacground-image: url("https://placehold.co/1920x567"); 
              background-size:cover; 
              }
            `,
            },
          },
          view: {
      
          },
    };
  return component;
};