import block from "./block.js";
import component from "./components/index.js"
import containerFaq2 from "./components/containerFaq2.js";
import contentFaq from "./components/contentFaq.js";
import faqContainer from "./components/faqContainer.js";
import tabContent from "./components/tabContent.js";
import tabLink from "./components/tabLink.js";
import tabsFaq from "./components/tabsFaq.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("faq2", component(opts));
  editor.DomComponents.addType("containerFaq2", containerFaq2(opts));
  editor.DomComponents.addType("contentFaq", contentFaq(opts));
  editor.DomComponents.addType("faqContainer", faqContainer(opts));
  editor.DomComponents.addType("tabContent", tabContent(opts));
  editor.DomComponents.addType("tabLink", tabLink(opts));
  editor.DomComponents.addType("tabsFaq", tabsFaq(opts));

    //block
  editor.BlockManager.add("faq2", block(opts));
};