export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname:'div', 
              name: "Item Suporte",
              attributes: { class: 'item-support-1' },
              components: [
               {
                 type:'image',
                 tagName:'img', 
                 attributes: {class:'icon-support-1',  src: 'https://placehold.co/45x45'},

               },
               {
                tagname:'div', 
                attributes:{class:'desc-support-1'},
                components:[
                {
                  type:'text', 
                  content:'título', 
                  attributes:{class:'title-support-1'},
                },
                {
                  type:'text', 
                  content:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et bibendum risus. Cras faucibus viverra dolor, at ',
                  attributes:{class:'text-support-1'}
                },
                ],
               },
              ],
              styles: `
              .item-support-1{
                display:flex; 
                align-items: center; 
                gap: 16px; 
              }
               .icon-support-1{
                width: 45px; 
               }
               .title-support-1{
                font-size: 12px;
                font-weigth: 500; 
                color:#95A1BB;
                text-transform:uppercase; 
               }
               .text-support-1{
                font-size:20px; 
                color: #363940; 
               }
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};