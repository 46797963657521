export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname:'div', 
              name: 'Coluna Serviços',
              attributes: { class: 'w-50 w-sm-100' },
              components: [
               {
                type:'image',
                attributes: { class: 'featured-image', src:'https://placehold.co/536x566'}, 
               },
               
              ],
              styles: `
              .w-50{
                width:50%; 
              }
              .featured-image{
                width:100%; 
                height: 100%; 
                object-fit: cover; 
              }
              @media (max-width:992px){
                .w-sm-100{
                  width: 100% !important; 
                }
              }
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};