export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname:'div', 
              name: "Coluna Suporte",
              attributes: { class: 'column column-50 p-20 gap-50' },
              components: [
                {
                  type: 'supportItem1', 
                },
                {
                  type: 'supportItem1', 
                },
                {
                  type: 'supportItem1', 
                },
              ],
              styles: `
               .gap-50{
                gap:50px; 
               }
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};