import block from "./block.js";
import component from "./components/index.js"
import columnEvents2 from "./components/columnEvents2.js";
import columnGaleryEvents2 from "./components/columnGaleryEvents2.js";
import columnsEvents2 from "./components/columnsEvents2.js";
import containerEvents2 from "./components/containerEvents2.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("events2", component(opts));
  editor.DomComponents.addType("columnEvents2", columnEvents2(opts));
  editor.DomComponents.addType("columnGaleryEvents2", columnGaleryEvents2(opts));
  editor.DomComponents.addType("columnsEvents2", columnsEvents2(opts));
  editor.DomComponents.addType("containerEvents2", containerEvents2(opts));

    //block
  editor.BlockManager.add("events2", block(opts));
};