export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              type: 'Container', 
              name: "Container Portifolio",
              attributes: { class: 'container d-flex gap-16 d-sm-column' },
              components: [
                {
                  type: 'divGrid1',
                },
                {
                  type: 'divGrid2',
                },
              ],
              styles: `
               .container{
                max-width: 1600px; 
             }
             .d-flex{
              display:flex;
             }
             .gap-16{
              gap:16px;
             }
             @media (max-width:992px){
              .d-sm-column{
                flex-direction: column; 
              }
             }
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};