export default (opts = {}) => {
    const component = {
        model: { 
            defaults: {
              name: "Contador",
              attributes: { class:'counter-container'},
              components: [
                {
                  tagName: 'div',
                  attributes: { class: 'counters' },
                  components: [
                    {
                      type:'counters',
                      attributes:{class:'counter-value font-40'},
                    },
                    {
                      type: 'text',
                      content: '+',
                      attributes: { class: 'sufix font-40'},
                    },
                  ],
                },
                   {
                  type: 'text',
                  content: 'Lorem',
                  attributes: { class: 'context font-20 light-gray' }
                },
              ],
              styles: `
              .context{
                margin-top:15px;
              }
              .sufix{
                color:#363940; 
                margin-left:8px; 
              }
              .counters{
                display:flex;
                justify-content:center; 
                aling-items:center;
              }
              .font-40{
                font-size:40px;
              }
              .font-20{
                font-size:20px; 
              }
              .light-gray{
                color:#A7A7A7;
              }
              .counter-container{
                display:flex;
                flex-direction:column; 
                align-items:center; 
              }
           `,
            },
          },
          view: {
      
          },
};
  return component; 
};