export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              type:'container',
              name: 'Container Topo',
              attributes: { class: 'container' },
              components: [
                {
                  type: 'columns1'
                }
              ],
              styles: `
             .container{
                max-width: 1600px; 
             }
            `,
            },
          },
          view: {
      
          },
    };
  
    return component;
  };

  