export default (opts = {}) => {
    const component = {
        model: { 
            defaults: {
              name: "Contador",
              attributes: { class:'counter-container-8'},
              components: [
                {
                    type: 'text',
                    content: 'Lorem ipsum dolor sit amet ',
                    attributes: { class: 'context' }
                  },
                {
                  tagName: 'div',
                  attributes: { class: 'counters-8' },
                  components: [
                    {
                        type: 'text',
                        content: '+',
                        attributes: { class: 'prefix font-40'},
                      },
                    {
                      type:'counters',
                      attributes:{class:'counter-value font-40'},
                    },
                    {
                        type: 'text',
                        content: 'lorem ipsum dolor',
                        attributes: { class: 'sufix font-40'},
                      },
                  ],
                },
              ],
              styles: `
              .context{
                font-size: 16px; 
                color: #363940; 
              }
              .prefix{
                color:#363940; 
              }
              .sufix{
                margin-left: 8px; 
                color:#363940; 
              }
              .counters-8{
                display:flex;
                aling-items:center;
                margin-top: 15px; 
              }
              .font-40{
                font-size:40px;
              }
              .counter-container-8{
                background: #fff; 
                padding: 20px; 
                width: 100%; 
                margin-top: 20px;
              }
           `,
            },
          },
          view: {
      
          },
};
  return component; 
};