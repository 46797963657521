export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              type:'div', 
              name: "Seção Suporte",
              attributes: { class: 'section bg-neutro' },
              components: [
                {
                  type: 'containerSupport1', 
                },
                
              ],
              styles: `
             .section{
               position:relative; 
               with:100vw; 
               padding: 75px 5%; 
             }
             .bg-neutro{
              background-color:#F6F7FA;
             }
             
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};