export default (opts = {}) => {
    const component = {
     model: {
      defaults: {
        type:'div', 
        name: 'Seção Depoimentos',
        attributes: { class: 'section section-full' },
        components: [
          {
            type: 'containerTestimony'
          }
        ],
        styles: `
       .section.section-full{
        padding:0; 
       }
      `,
       },
    },
    view: {

    },
    }; 
    return component; 
};