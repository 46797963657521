export default (opts = {}) => {
    const script = function () {
  
      var acc = document.getElementsByClassName("accordion-header-4");
      var i;
      
      for (i = 0; i < acc.length; i++) {
        acc[i].addEventListener("click", function() {
          if(this.classList.contains('is-open')){
            this.classList.remove("is-open");
          }
          else{
            this.classList.add("is-open");
          }
          var panel = this.nextElementSibling;
          if (panel.style.display === "block") {
            panel.style.display = "none";
          } else {
            panel.style.display = "block";
          }
        });
      } 
    };
      
      const component = {
          model: {
              defaults: {
                script, 
                name: "Div",
                attributes:{class:'d-flex d-sm-column w-100 gap-20'},
                components: [
                  {
                    type: 'div',
                    attributes: { class: 'w-50 w-sm-100'},
                    components: [
                        {
                            type: 'accordion4'
                        },
                        {
                            type: 'accordion4'
                        },
                        {
                            type: 'accordion4'
                        },
                    ]
                  },
                  {
                    type: 'div',
                    attributes: { class: 'w-50 w-sm-100'},
                    components: [
                        {
                            type: 'accordion4'
                        },
                        {
                            type: 'accordion4'
                        },
                        {
                            type: 'accordion4'
                        },
                    ]
                  }
                ],
                styles: `
                .d-flex{
                    display: flex; 
                }
                .w-100{
                    width:100%; 
                }
                .w-50{
                    width: 50%; 
                }
                .gap-20{
                    gap: 20px; 
                }
                @media (max-width: 992px){
                    .d-sm-column{
                        flex-direction: column; 
                    }
                    .w-sm-100{
                        width: 100% !important; 
                    }
                }

              `,
              },
            },
            view: {
        
            },
  };
    return component; 
  };