export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname:'div', 
              name: "Coluna Formulário",
              attributes: { class: 'column column-50 p-20' },
              components: [
                {
                  type:'text', 
                  name:'Subtitulo',
                  content: 'Subtítulo', 
                  attributes: {class:'subtitle text-uppercase'},
                }, 
                {
                  tagName: 'h2',
                  type:'text',
                  name:'Titulo',
                  components: 'Formulário final',
                  attributes: {class: 'principal-title'}, 
                  traits: [
                    {
                      type: "select",
                      options: [
                        { value: "h1", name: opts.labelN1 },
                        { value: "h2", name: opts.labelN2 },
                        { value: "h3", name: opts.labelN3 },
                        { value: "h4", name: opts.labelN4 },
                        { value: "h5", name: opts.labelN5 },
                        { value: "h6", name: opts.labelN6 },
                      ],
                      label: 'Tamanho',
                      name: "tagName",
                      changeProp: 1,
                    },
                    {
                      type:'id',
                      name:'id',
                      Label:'id',
                    }
                  ],
                },
                {
                  type: "div", 
                  attributes: { class: 'margin-top-30 w-100' },
                  components: [
                    {
                      type: "cubosuite-form", 
                    }
                  ],
                },
                {
                  type: "text",
                  content: 'Ao responder o presente formulário, declaro, COMO TITULAR dos dados aqui fornecidos, que autorizo a empresa PROSPECTA DIGITALl, aqui denominada CONTROLADORA, disponha dos meus dados pessoais e dados pessoais sensíveis, de acordo com os artigos 7° e 11 da Lei n° 13.709/ 2018. Os dados serão utilizados para fim de contato através do whatsapp, e-mail e telefone.',
                  attributes: { class: 'lgpd-text margin-top-20' }
                }
              ],
              styles: `
              .lgpd-text{
                font-size:8px;
                color: #95A1BB;
                font-weight: 300; 
              }
              .form-control{
                background:#F6F7FA; 
                padding:10px; 
                font-size:13px; 
                color: #9A9A9A;
                border:none;
                width:100%;
                margin-top:8px;
              }
              .label{
                font-size:13px;
              }
              .form-group{
                margin-bottom:15px;
              }
              .btn.btn-primary {
                background-color: #95a1bb;
                width: 100%;
                padding: 15px 15px 15px 15px;
                margin: 20px 0 0 0;
                color: white;
                font-size: 15px;
                border: 0 none black;
              }
              .margin-top-30{
                margin-top:30px; 
              }
              .w-100{
                width:100%;
              }
               .principal-title{
                font-size: 30px; 
                color: #363940;
                margin:0;
               }
               .body-text{
                font-size: 16px; 
                color: #363940; 
                margin-top:30px;
               }
               .subtitle{
                font-size: 13px; 
                color: #747474; 
               }
               .text-uppercase{
                text-transform: uppercase;
               }
               .margin-top-20{
                margin-top:20px; 
              }
      
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};