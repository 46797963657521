import block from "./block.js";
import component from "./components/index.js"
import columnImageTestimony from "./components/columnImageTestimony.js";
import columnsTestimony1 from "./components/columnsTestimony1.js";
import columnTestimony1 from "./components/columnTestimony1.js";
import containerTestimony from "./components/containerTestimony.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("testimony1", component(opts));
  editor.DomComponents.addType("columnImageTestimony", columnImageTestimony(opts));
  editor.DomComponents.addType("columnsTestimony1", columnsTestimony1(opts));
  editor.DomComponents.addType("columnTestimony1", columnTestimony1(opts));
  editor.DomComponents.addType("containerTestimony", containerTestimony(opts));

  //block
  editor.BlockManager.add("testimony1", block(opts));
};