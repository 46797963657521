export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname:'div', 
              name: "Coluna Videos",
              attributes: { class: 'column column-50 p-20' },
              components: [
                {
                  type: 'video', 
                  attributes:{class:'side-video'}, 
                },
                
              ],
              styles: `
              .side-video{
                width:100%;
              }
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};