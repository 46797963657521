export default (opts = {}) => {
  const component = {
    model: {
      defaults: {
        tagname: "div",
        name: "Coluna Sobre",
        classes: ['column', 'column-50', 'pattern-padding'],
        attributes: { "data-js": "countdown"},
        components: [
          {
            type: "text",
            content: "Subtítulo",
            name: "Subtitulo",
            classes: ['subtitle', 'text-uppercase'],
          },
          {
            tagName: "h2",
            type: "text",
            name: "Titulo",
            components: "Quem somos",
            classes: ['principal-title'],
            traits: [
              {
                type: "select",
                options: [
                  { value: "h1", name: opts.labelN1 },
                  { value: "h2", name: opts.labelN2 },
                  { value: "h3", name: opts.labelN3 },
                  { value: "h4", name: opts.labelN4 },
                  { value: "h5", name: opts.labelN5 },
                  { value: "h6", name: opts.labelN6 },
                ],
                label: "Tamanho",
                name: "tagName",
                changeProp: 1,
              },
              {
                type: "id",
                name: "id",
                Label: "id",
              },
            ],
          },
          {
            type: "text",
            content:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam in neque non urna consequat dictum et at justo. Nullam urna ante, volutpat ac sapien ut, finibus efficitur quam. Nam aliquam, lorem sed placerat eleifend, libero dui sodales leo, quis mollis urna libero nec orci. Morbi ullamcorper nec ante a sollicitudin. Etiam at nunc consequat sapien aliquam dapibus in vitae ipsum. Duis ac mattis orci, nec maximus sem. Nam sollicitudin bibendum nulla, id interdum ipsum euismod eget. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Morbi a dolor eros. Sed faucibus purus neque, ut feugiat ex viverra sit amet. Duis non ornare tellus. Nulla lacinia mi ante, id viverra velit laoreet nec. Nam pretium ac turpis vel pharetra. Mauris cursus facilisis maximus. Sed ultricies augue viverra pharetra luctus. Integer sollicitudin velit a faucibus dapibus. ",
            classes: ['body-text']
          },
          {
            type: "button",
          },
        ],

        styles: `
           .principal-title{
            font-size: 30px;
            color: #363940;
            margin:0;
           }
           .body-text{
            font-size: 16px;
            color: #363940;
            margin-top:30px;
           }
           .column{
            display:flex;
            flex-direction:column;
            align-items: flex-start;
           }
           .column-50{
            width:50%;
           }

           @media (max-width:992px){
            .column-50{
              width:100%;
            }
           }
           .subtitle{
            font-size: 13px;
            color: #747474;
           }
           .text-uppercase{
            text-transform: uppercase;
           }
           .pattern-padding{
             padding:75px 5%;
           }
        `,
      },
    },
    view: {},
  };

  return component;
};
