import block from "./block.js";
import component from "./components/index.js"
import cardDifferences2 from "./components/cardDifferences2.js";
import containerDifferences2 from "./components/containerDifferences2.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("differences2", component(opts));
  editor.DomComponents.addType("cardDifferences2", cardDifferences2(opts));
  editor.DomComponents.addType("containerDifferences2", containerDifferences2(opts));

    //block
  editor.BlockManager.add("differences2", block(opts));
};