export default (opts = {}) => {
  const component = {
    model: {
      defaults: {
        type:'div', 
        name: "Seção Sobre",
        attributes: { class: 'section section-full' },
        components: [
          {
            type: 'containerAbout'
          }          
        ],
        styles: `
       .section.section-full{
        padding:0; 
       }
      `,
       },
    },
    view: {

    },
  };

  return component;
};

