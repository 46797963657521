export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              name: "Acordião",
              attributes: { class: 'accordion4' },
              components: [
                {
                  type: 'text',
                  content: 'Lorem ipsum dolor sit amet',
                  attributes: { class: 'accordion-header-4' },
                },
                {
                  type: 'text',
                  content: 'Mauris luctus tincidunt venenatis. Nulla viverra maximus eleifend. Quisque maximus gravida leo sed aliquet. Quisque luctus quam tortor, sit amet ultricies arcu molestie nec. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Duis nec aliquet urna. Proin eu lacus orci. Nam quis augue et mauris accumsan ultricies ullamcorper et felis. Sed vel laoreet nunc. Sed in metus porta, lobortis nunc vitae, sodales tortor. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Vestibulum vel pellentesque erat, at feugiat massa. Morbi semper vel metus cursus rutrum.',
                  attributes: { class: 'accordion-content-4' },
                }
              ],
              styles: `
              .accordion4{
                width:100%; 
                padding: 20px; 
                border-bottom: 1px solid #CFD5E1; 
                border-radius:4px; 
                cursor:pointer;
                margin-bottom:20px;
                position:relative; 
              }
              .accordion-header-4{
                font-size:18px; 
                color:#363940; 
                font-weight:500; 
                position:absolute; 
                top:0; 
                left:0; 
                width:100%;
                heigth:100%; 
                padding:10px 20px; 
              }
              .accordion-header-4.is-open{
                position:static; 
                width:auto; 
                heigth:auto;
                padding:0; 
              }
              .accordion-header-4:after{
                content: '+';
                font-size: 18px;
                color: #363940;
                font-weight:700; 
                float: right;
                margin-left: 5px;
              }
              .accordion-header-4.is-open:after{
                content: '-';
              }
              .accordion-content-4{
                margin-top:20px; 
                font-size:15px;
                color:#363940;
                display: none;
      
              }
              
            `,
            },
          },
          view: {
      
          },
};
  return component; 
};