export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname:'div', 
              name: 'Coluna Serviços',
              attributes: { class: 'column column-70 p-20' },
              components: [
               {
                type:'rowCardService2',
               },
               {
                type:'rowCardService2',
               },
              ],
              styles: `
              .p-20{
                padding:20px;
               }
               .column{
                display:flex; 
                flex-direction:column;
                align-items:flex-start;
               }
               .column-70{
                width:70%; 
               }
               .column-30{
                width:30%; 
               }
               @media (max-width:992px){
                .column-70{
                  width:100%; 
                }
                .column-30{
                  width:100%; 
                }
                .d-sm-column{
                  flex-direction: column !important; 
                }
              }
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};