import itemCheckList from "./checkListItem";

export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname:'div', 
              name: "Container Checklist",
              attributes: { class: 'container-cheklist' },
              components: [
                {
                  type: 'checkListItem', 
                },
                {
                  type: 'checkListItem', 
                },
                {
                  type: 'checkListItem', 
                },
                {
                  type: 'checkListItem', 
                },
              ],
              styles: `
             .container-cheklist{
              width:50%; 
            }
             @media (max-width:992px){
              .container-cheklist{
                width:100%; 
              }
             }
             
            `,
             },
          },
          view: {
      
          },
    };
  
    return component;
  };
  