
export default (opts = {}) => {
  const component = {
      model: {
        defaults: {
          type:'div',
          name: 'Div',
          components: [
            {
              type: 'link', 
              attributes: { class: 'scroll-down-2' },
              components: [
                {
                  type:'image',
                  attributes:{class:'arrow-down'},
                },
                {
                 type:'text',
                 content:'Arraste para baixo e saiba mais',
                 attributes: { class: 'text-scroll' }
                },
              ]
            }
           
          ],
          styles: `
          .scroll-down-2{
            margin-top:30px;
            display:flex;
            justify-content:center; 
          }
          .arrow-down{
            width:20px;
            height: 20px; 
          }
          .text-scroll{
              font-size:15px;
              color:#707070; 
              margin-left:12px; 
          }
  
        `,
        },
      },
      view: {
  
      },
    };
    return component;
}; 