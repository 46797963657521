import block from "./block.js";
import component from "./components/index.js"
import columnCall4 from "./components/columnCall4.js";7
import divForm4 from "./components/divForm4.js";
import imageForm4 from "./components/imageForm4.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("topPage4", component(opts));
  editor.DomComponents.addType("columnCall4", columnCall4(opts));
  editor.DomComponents.addType("divForm4", divForm4(opts));
  editor.DomComponents.addType("imageForm4", imageForm4(opts));

    //block
  editor.BlockManager.add("topPage4", block(opts));
};