export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname: 'div',
              name: 'Coluna Topo',
              attributes: { class: 'column column-50 p-20 aling-item-md-center' },
              components: [
                {
                  tagname:'img',
                  type: 'image',
                  attributes: {class: 'logo margin-bottom-50', src: 'https://placehold.co/150x50'}, 
                },
                {
                  
                  tagName: 'h1',
                  type:'text',
                  name:'Titulo Principal',
                  components: 'Topo da Landing Page',
                  attributes: { class: 'font-48 title-color font-medium' },
                  traits: [
                    {
                      type: "select",
                      options: [
                        { value: "h1", name: opts.labelN1 },
                        { value: "h2", name: opts.labelN2 },
                        { value: "h3", name: opts.labelN3 },
                        { value: "h4", name: opts.labelN4 },
                        { value: "h5", name: opts.labelN5 },
                        { value: "h6", name: opts.labelN6 },
                      ],
                      label: 'Tamanho',
                      name: "tagName",
                      changeProp: 1,
                    },
                    {
                      type:'id',
                      name:'id',
                      Label:'id',
                    }
                  ],
                },
                {
                  type: 'text',
                  content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et bibendum risus. Cras faucibus viverra dolor, at vehicula',
                  attributes: { class: 'subtitle-color font-18 margin-top-20' },
                },
              ],
              styles: `
               .font-48{
                font-size:48px;
               }
               .font-18{
                font-size:18px; 
               }
               .title-color{
                color:#363940;
               }
               .subtitle-color{
                color:#95A1BB; 
               }
               .font-medium{
                font-weight: 500; 
               }
               .margin-top-20{
                margin-top:20px; 
               }
               .margin-bottom-50{
                margin-bottom:50px;
               }
               .column{
                display:flex; 
                flex-direction:column;
                align-items:flex-start; 
               }
               .column-50{
                width:50%; 
               }
               .logo{
                width:100px;
               }
               @media (max-width:992px){
                .column-50{
                  width:100%; 
                }
               }
            `,
            },
          },
          view: {
      
          },
    };
  
    return component;
  };
  