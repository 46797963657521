export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname:'div', 
              name: 'Div',
              attributes: { class: 'row-stamps-2' },
              components: [
               {
                 type:'image', 
                 tagname:'img',  
                 attributes:{class:'img-stamps-2', src:'https://placehold.co/260x86'}
               },
               {
                type:'image', 
                tagname:'img', 
                attributes:{class:'img-stamps-2', src:'https://placehold.co/260x86'}
              },
              {
                type:'image', 
                tagname:'img', 
                attributes:{class:'img-stamps-2', src:'https://placehold.co/260x86'}
              },
              {
                type:'image', 
                tagname:'img', 
                attributes:{class:'img-stamps-2', src:'https://placehold.co/260x86'}
              },
              ],
              styles: `
               .row-stamps-2{
                display:flex; 
                gap:16px; 
                flex-wrap: wrap;
                margin-bottom:16px;
               }
               .img-stamps-2{
                width:48%; 
               }
               @media (max-width:992px){
                .img-stamps-2{
                  width:46%;
                }
               }
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};