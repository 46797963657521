export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              type: 'Container',
              name: "Container Modelos",
              attributes: { class: 'container align-center' },
              components: [
                {
                  type: 'text',
                  content: 'Subtítulo',
                  name: 'Subtitulo',
                  attributes: { class: 'subtitle text-uppercase margin-right-50 m-sm-r-0' },
                },
                {
                  tagName: 'h2',
                  type: 'text',
                  name: 'Titulo',
                  components: 'Modelos de negócio',
                  attributes: { class: 'principal-title margin-right-50 m-sm-r-0' },
                  traits: [
                    {
                      type: "select",
                      options: [
                        { value: "h1", name: opts.labelN1 },
                        { value: "h2", name: opts.labelN2 },
                        { value: "h3", name: opts.labelN3 },
                        { value: "h4", name: opts.labelN4 },
                        { value: "h5", name: opts.labelN5 },
                        { value: "h6", name: opts.labelN6 },
                      ],
                      label: 'Tamanho',
                      name: "tagName",
                      changeProp: 1,
                    },
                    {
                      type: 'id',
                      name: 'id',
                      Label: 'id',
                    },
                  ],
                },
                {
                  type: 'text',
                  content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et bibendum risus. Cras faucibus viverra dolor, at  ',
                  attributes: { class: 'body-text text-center w-40 w-sm-100' },
                },
                
                {
                  type:'modelBusiness3',
                },
                {
                  type: 'button',
                },
              ],
              styles: `
             .container{
                max-width: 1600px; 
             }
             .principal-title{
              font-size: 30px; 
              color: #363940; 
              margin:0;
             }
             .body-text{
              font-size: 16px; 
              color: #363940; 
              margin-top:30px;
             }
             .subtitle{
              font-size: 13px; 
              color: #747474; 
             }
             .text-uppercase{
              text-transform: uppercase;
             }
             .text-center{
              text-align: center;
             }
             .w-40{
              width:40%; 
            }
            .align-center{
              display:flex; 
              flex-direction: column; 
              align-items:center; 
            }
            @media (max-width:992px){
              .w-sm-100{
                width:100% !important;
              }
            }
            `,
            },
          },
          view: {
      
          },
};
  return component; 
};