import block from "./block.js";
import component from "./components/index.js"
import containerTimeLine1 from "./components/containerTimeLine1.js";
import itemTimeLine1 from "./components/itemTimeLine1.js";
import rowsTimeLine1 from "./components/rowsTimeLine1.js";
import rowTimeLine1 from "./components/rowTimeLine1.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("timeLine1", component(opts));
  editor.DomComponents.addType("containerTimeLine1", containerTimeLine1(opts));
  editor.DomComponents.addType("itemTimeLine1", itemTimeLine1(opts));
  editor.DomComponents.addType("rowsTimeLine1", rowsTimeLine1(opts));
  editor.DomComponents.addType("rowTimeLine1", rowTimeLine1(opts));

  //block
  editor.BlockManager.add("timeLine1", block(opts));
};