export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
                type: 'link', 
                traits: [
                  {
                    type:'id',
                    name:'id',
                    Label:'id',
                  },
                  {
                    type: 'text',
                    name:'title',
                    label: 'Title', 
                  },
                  {
                    type: 'text',
                    name: 'href',
                    label: 'Href',
                  },
                  {
                    type:'select',
                    name:'target',
                    id:'target',
                    label: 'Target',
                    options: [
                    { value: '', name: 'Esta Janela'},
                    { value: '_blank', name: 'Nova Janela'},
                  ]
                  },
                  {
                    type: 'my-textarea-trait', 
                    name: 'comment',
                    id: 'comment',
                    label: 'Comentário',
                  }
                ],
              components:[
                {
                    type:'text',
                    content:'Lorem ipsum',
                },
                
              ],
              styles: `
              .gjs-dashed [comment]:after {
                content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAAQwAAAEMBuP1yoAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAADuSURBVDiNpdOtTkNBEAXgb9qbgCiW9DUQGH4EBN6CEBIElXVINAKBoo5KZDUPAK8AKN4BAooMZm9yIb3tDZxkxM6ec3J2JhuZ6T+oICLOcYS1jro3TDPzCnYxwyAzdSkMimarh33MMvO9a+zCneGwQjQvI2KEUYv2OjNvm/RqDmmKuxaDz9+NeQYjjFsMLnGzzGBSUvw5wYkFM0BzBip8oVc3MnNSUixDr2ht4h5D9Bu77mO1pYZFs1GTz/CMcTnv4WlBPeI0M0X9FyJijBWsYxvHmfnS5R1NXOADO13E/NzCKw4y86GLsEb89zt/A5uEdee10aPNAAAAAElFTkSuQmCC');
                width: 20px;
                height: 20px;
                background: #32B1EA;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 0;
                left: 0;
              }
            .gjs-dashed [comment]{
              position: relative;
            }`,
            },
          },
          view: {
      
          },
};
  return component; 
};