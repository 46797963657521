<template>
  <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true">
    <template #default>
      <div class="spinner spinner--socker" style="width: 100px; height: 100px">
        <div class="spinner-inner" style="transform: scale(0.7)">
          <div class="cube panelLoad">
            <div class="cube-face cube-face-front">L</div>
            <div class="cube-face cube-face-back">O</div>
            <div class="cube-face cube-face-left">A</div>
            <div class="cube-face cube-face-right">D</div>
            <div class="cube-face cube-face-bottom">I</div>
            <div class="cube-face cube-face-top">N'</div>
          </div>
        </div>
      </div>
    </template>
    <template #after>
      <h3>Verificando Token</h3>
    </template>
  </loading>
</template>

<script setup>
import { ref } from "vue";
import { useRouter, useRoute } from 'vue-router'
import { useStorage } from '@vueuse/core'
import Loading from "vue-loading-overlay";

const isLoading = ref(true);

const route = useRoute();
const router = useRouter()

const token = useStorage('pagebuilder-token', '')
token.value = route.params.token
console.log('verify', { token, route })
router.push({
  name: 'Pagebuilder',
  params: {
    id: route.params.id
  }
})

</script>