import block from "./block.js";
import component from "./components/index.js"
import cardDifferences1 from "./components/cardDifferences1.js";
import columnCardDifferences1 from "./components/columnCardDifferences1.js";
import columnDifferences1 from "./components/columnDifferences1.js";
import columnsDifferences1 from "./components/columnsDifferences1.js";
import containerDifferences1 from "./components/containerDifferences1.js";
import rowCardDifferences1 from "./components/rowCardDifferences1.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("differences1", component(opts));
  editor.DomComponents.addType("cardDifferences1", cardDifferences1(opts));
  editor.DomComponents.addType("columnCardDifferences1", columnCardDifferences1(opts));
  editor.DomComponents.addType("columnDifferences1", columnDifferences1(opts));
  editor.DomComponents.addType("columnsDifferences1", columnsDifferences1(opts));
  editor.DomComponents.addType("containerDifferences1", containerDifferences1(opts));
  editor.DomComponents.addType("rowCardDifferences1", rowCardDifferences1(opts));

    //block
  editor.BlockManager.add("differences1", block(opts));
};