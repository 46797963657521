import block from "./block.js";
import component from "./components/index.js"
import containerNumbers3 from "./components/containerNumbers3.js";
import counters3 from "./components/counters3.js";
import rowNumbers3 from "./components/rowNumbers3.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("bigNumber3", component(opts));
  editor.DomComponents.addType("containerNumbers3", containerNumbers3(opts));
  editor.DomComponents.addType("counters3", counters3(opts));
  editor.DomComponents.addType("rowNumbers3", rowNumbers3(opts));

    //block
  editor.BlockManager.add("bigNumber3", block(opts));
};