import block from "./block.js";
import component from "./components/index.js"
import containerTimeLine4 from "./components/containerTimeLine4.js";
import itemTimeLine4 from "./components/itemTimeLine4.js";
import rowTimeLine4 from "./components/rowTimeLine4.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("timeLine4", component(opts));
  editor.DomComponents.addType("containerTimeLine4", containerTimeLine4(opts));
  editor.DomComponents.addType("itemTimeLine4", itemTimeLine4(opts));
  editor.DomComponents.addType("rowTimeLine4", rowTimeLine4(opts));

  //block
  editor.BlockManager.add("timeLine4", block(opts));
};