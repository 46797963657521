import block from "./block.js";
import component from "./components/index.js"
import columnImageNumbers1 from "./components/columnImageNumbers1.js";
import columnNumbers1 from "./components/columnNumbers1.js";
import columnsNumbers1 from "./components/columnsNumbers1.js";
import containerNumbers1 from "./components/containerNumbers1.js";
import counters1 from "./components/counters1.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("bigNumber1", component(opts));
  editor.DomComponents.addType("columnImageNumbers1", columnImageNumbers1(opts));
  editor.DomComponents.addType("columnNumbers1", columnNumbers1(opts));
  editor.DomComponents.addType("columnsNumbers1", columnsNumbers1(opts));
  editor.DomComponents.addType("containerNumbers1", containerNumbers1(opts));
  editor.DomComponents.addType("counters1", counters1(opts));

    //block
  editor.BlockManager.add("bigNumber1", block(opts));
};