export default (opts = {}) => {
  const component = {
    model: {
      defaults: {
      tagName: 'select',
      droppable: false, 
      traits: [
        'name',
        'id', 
        { type: 'checkbox', name: 'required' },
      ],
      },
    },
    view: {

    },
  };

  return component;
};
