export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
                name: "Div",
                attributes: { class: 'd-flex gap-16  margin-top-50 d-sm-column' },
                components: [
                    {
                        type: 'itemUnits2',
                    },
                    {
                        type: 'itemUnits2',
                    },
                    {
                        type: 'itemUnits2',
                    },
                ],
                styles: `
                .gap-16{
                    gap: 16px; 
                }
                .margin-top-16{
                    margin-top: 16px;
                }
                .d-flex{
                    display: flex;
                }
                .margin-top-50{
                    margin-top: 50px;
                }

                @media (max-width: 992px){
                    .d-sm-column{
                        flex-direction: column; 
                    }
                }
      
            `,
            },
        },
        view: {

        },
    };
    return component;
};