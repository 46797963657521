export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              type:'container',
              name: "Container Diferenciais",
              attributes: { class: 'container' },
              components: [
                {
                  type: 'columnsDifferences4',
                },
      
              ],
              styles: `
             .container{
                max-width: 1600px; 
             }
             .bg-branco{
              background: #fff; 
             }
             .p-50{
              padding: 50px; 
             }
             @media (max-width:992px){
              .p-sm-20{
                padding: 20px; 
              }
             }
            `,
            },
          },
          view: {
      
          },
};
  return component; 
};