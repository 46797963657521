export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname:'div', 
              name: "Checklist",
              attributes: { class: 'item-cheklist' },
              components: [
                {
                  type: 'image', 
                  attributes: {class: 'icon-checklist',  src: 'https://path/image'}
                },
                {
                  type:'text',
                  content:'Lorem ipsum dolor sit amet',
                  attributes:{class:'text-checklist'},
                },
              ],
              styles: `
              .item-cheklist {
                display: flex;
                align-items: center;
                margin-bottom:10px;
              }
              .icon-checklist {
                width: 20px;
                height: 20px;
              }
               .text-checklist{
                font-size:16px; 
                color:#363940; 
                margin-left:16px; 
               }
            `,
             },
          },
          view: {
      
          },
    };
  
    return component;
  };
  