import block from "./block.js";
import component from "./components/index.js"
import prints2_comp from "./components/prints2.js";
import itemPrints2 from "./components/itemPrints2.js";
import containerPrints2 from "./components/containerPrints2.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("prints2", component(opts));
  editor.DomComponents.addType("prints2_comp", prints2_comp(opts));
  editor.DomComponents.addType("itemPrints2", itemPrints2(opts));
  editor.DomComponents.addType("containerPrints2", containerPrints2(opts));

    //block
  editor.BlockManager.add("prints2", block(opts));
};