export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname:'div', 
              name: "Div",
              attributes: { class: 'row-event' },
              components: [      
                {
                  type:'itemEvents1',
                },
                {
                  type:'itemEvents1',
                },
              ],
              styles: `
              .row-event {
                display: flex;
                gap: 16px;
              }
              @media (max-width:992px){
                .row-event{
                  flex-direction:column;
                  gap: 16px;
                }
              }
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};