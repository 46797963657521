export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname: 'div',
              name: 'Coluna Serviços',
              attributes: { class: 'w-33 w-sm-100 col-justify-center' },
              components: [
                {
                  type: 'text',
                  name: 'Subtitulo',
                  content: 'Subtítulo',
                  attributes: { class: 'subtitle text-uppercase' },
                },
                {
                  tagName: 'h2',
                  type: 'text',
                  name: 'Titulo',
                  components: 'Cards para serviços e informações',
                  attributes: { class: 'principal-title' },
                  traits: [
                    {
                      type: "select",
                      options: [
                        { value: "h1", name: opts.labelN1 },
                        { value: "h2", name: opts.labelN2 },
                        { value: "h3", name: opts.labelN3 },
                        { value: "h4", name: opts.labelN4 },
                        { value: "h5", name: opts.labelN5 },
                        { value: "h6", name: opts.labelN6 },
                      ],
                      label: 'Tamanho',
                      name: "tagName",
                      changeProp: 1,
                    },
                    {
                      type: 'id',
                      name: 'id',
                      Label: 'id',
                    }
                  ],
                },
                {
                  type: 'text',
                  content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et bibendum risus. Cras faucibus viverra dolor, at  ',
                  attributes: { class: 'body-text' },
                },
                {
                  type: 'button',
                },
              ],
              styles: `
              .body-text{
                font-size: 16px; 
                color: #363940; 
                margin-top:30px;
               }
               .principal-title{
                font-size: 30px; 
                color: #363940;
                margin:0;
               }
               .body-text{
                font-size: 16px; 
                color: #363940; 
                margin-top:30px;
               }
               .text-center{
                text-align: center;
               }
               .subtitle{
                font-size: 13px; 
                color: #747474; 
               }
               .text-uppercase{
                text-transform: uppercase;
               }
               .margin-top-20{
                margin-top:20px; 
              }
              .col-justify-center{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
              }
              .w-70{
                width:70%; 
              }
              @media (max-width:992px){
                .w-sm-100{
                  width:100% !important; 
                }
              }
            `,
            },
          },
          view: {
      
          },
};
  return component; 
};