export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
                name: 'Div',
                attributes: { class: 'd-flex gap-50  margin-top-50 d-sm-column' },
                components: [
                    {
                        type: 'itemTestimony3',
                    },
                    {
                        type: 'itemTestimony3',
                    },
                ],
                styles: `
                .gap-50{
                    gap: 50px; 
                }
                .margin-top-50{
                    margin-top: 50px;
                }

                @media (max-width: 992px){
                    .d-sm-column{
                        flex-direction: column; 
                    }
                }
      
            `,
            },
        },
        view: {

        },
    };
    return component;
};