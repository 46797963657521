import block from "./block.js";
import component from "./components/index.js"
import accordion2 from "./components/accordion2.js";
import columnFaq3 from "./components/columnFaq3.js";
import columnImageFaq3 from "./components/columnImageFaq3.js";
import columnsFaq3 from "./components/columnsFaq3.js";
import containerFaq3 from "./components/containerFaq3.js";
import divAccordion2 from "./components/divAccordion2.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("faq3", component(opts));
  editor.DomComponents.addType("accordion2", accordion2(opts));
  editor.DomComponents.addType("columnFaq3", columnFaq3(opts));
  editor.DomComponents.addType("columnImageFaq3", columnImageFaq3(opts));
  editor.DomComponents.addType("columnsFaq3", columnsFaq3(opts));
  editor.DomComponents.addType("containerFaq3", containerFaq3(opts));
  editor.DomComponents.addType("divAccordion2", divAccordion2(opts));

    //block
  editor.BlockManager.add("faq3", block(opts));
};