export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              name: "Perguntas FAQ",
              attributes: { class: 'tab-link' },
              components: [
                {
                  type: 'text',
                  content: 'Lorem ipsum dolor',
                },
              ],
              styles: `
              .tab-link{
                color:#363940;
                font-size:16px; 
                margin-bottom:18px;
                position: relative;
              }
              .tab-link.active{
                color: #95A1BB;
              }
              .tab-link:after{
                content: '+';
                font-size: 18px;
                color: #363940;
                font-weight: 700;
                position:absolute; 
                left: -20px;
                top: 0;
              }
              .tab-link.active:after{
                content:'-'; 
                color: #95A1BB;
              }
            `,
            },
          },
          view: {
      
          },
};
  return component; 
};