export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              type: 'Container', 
              name: "Container Eventos",
              attributes: { class: 'container align-center' },
              components: [
                {
                    type: 'columnsEvents2'
                }
              ],
              styles: `
               .container{
                max-width: 1600px; 
             }
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};