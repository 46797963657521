export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname:'div', 
              name: 'Prints',
              attributes: { class: 'display-flex  justify-center margin-top-50' },
              components: [
                {
                  tagname:'img',
                  type:'image',
                  attributes: {class: 'img-print', src:'https://placehold.co/427x109'}, 
                },
                {
                  tagname:'img',
                  type:'image',
                  attributes: {class: 'img-print margin-left-30', src:'https://placehold.co/512x147'}, 
                },
                
              ],
              styles: `
                .display-flex{
                  display:flex;
                }
                .justify-center{
                  justify-content:center;
                }
                .margin-top-50{
                  margin-top:50px;
                }
                .margin-left-30{
                  margin-left:30px;
                }
                .img-print{
                  max-width:50%;
                }
      
            `,
             },
          },
          view: {
      
          },
    }; 
    return component; 
};