import block from "./block.js";
import component from "./components/index.js"
import columnBigNumbers6 from "./components/columnBigNumbers6.js";
import columnNumbers6 from "./components/columnNumbers6.js";
import columnsNumbers6 from "./components/columnsNumbers6.js";
import containerNumbers6 from "./components/containerNumbers6.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("bigNumber6", component(opts));
  editor.DomComponents.addType("columnBigNumbers6", columnBigNumbers6(opts));
  editor.DomComponents.addType("columnNumbers6", columnNumbers6(opts));
  editor.DomComponents.addType("columnsNumbers6", columnsNumbers6(opts));
  editor.DomComponents.addType("containerNumbers6", containerNumbers6(opts));

    //block
  editor.BlockManager.add("bigNumber6", block(opts));
};