import blocks from './blocks/index';
import en from './locale/en';

export default (editor, opts = {}) => {
  const options = { ...{
    i18n: {},
  },  ...opts };

  blocks(editor, options);

  editor.I18n && editor.I18n.addMessages({
      en,
      ...options.i18n,
  });

//
};