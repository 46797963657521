export default (editor, opts = {}) => {
  const component = {
    isComponent(el) {
      if (el?.tagName === "div" && el.getAttribute("componentName") === 'cuboFormRow') {
        return { type: "cuboFormRow" };
      }
    },
    model: {
      defaults: {
        tagName: "div",
        attributes: { componentName: "cuboFormRow" },
        droppable: false,
        classes: ['cubo-form-group', 'column-sm-12'],
        components: [],
        traits: [
          {
            label: "Colunas",
            type: "number",
            name: "columns",
            changeProp: true,
            min: 1,
            max: 12,
            required: true,
          }
        ],

        columns: 12,

        "script-props": ["columns"],

        styles: `
          .cubo-form-group{
            width: 100%;
            display: flex;
            flex-direction: column;
            padding: 5px;
          }

          .cubo-form-group label{
            font-size: .8rem;
            font-weight: 600;
            margin-bottom: 5px;
          }
        `
      },
      init() {
        const classes = this.get('classes').filter(model => /^column-\d+$/.test(model.id))
        const columns = classes.length > 0 ? classes[0].id.replaceAll('column-', '') : 12
        this.set('columns', columns)
        this.on('change:columns', (event) => {
          this.get('classes').filter(model => /^column-\d+$/.test(model.id))
            .forEach(model => this.removeClass(model.id));
          this.addClass(`column-${event.changed.columns}`)
        });
      },
    },
    view: {},
  };

  return component;
};
