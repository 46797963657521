export default (editor, opts = {}) => {
  const domc = editor.DomComponents;
  const pipes = opts.pipes ?? [];
  const customfields = opts.customfields ?? [];
  
  const script = function (props) {
    if (typeof Cookies === "undefined") {
      var script = document.createElement("script");
      script.src =
        "https://cdn.jsdelivr.net/npm/js-cookie@3.0.5/dist/js.cookie.min.js";
      script.type = "text/javascript";
      document.getElementsByTagName("head")[0].appendChild(script);
    }

    if (typeof Pristine === "undefined") {
      var script = document.createElement("script");
      script.src =
        "https://cdn.jsdelivr.net/npm/pristinejs@1.1.0/dist/pristine.min.js";
      script.type = "text/javascript";
      document.getElementsByTagName("head")[0].appendChild(script);
    }

    if (typeof VMasker === "undefined") {
      var script = document.createElement("script");
      script.src =
        "//unpkg.com/vanilla-masker@1.1.1/build/vanilla-masker.min.js";
      script.type = "text/javascript";
      document.getElementsByTagName("head")[0].appendChild(script);
    }

    setTimeout(() => {
      Pristine.addMessages("pt-BR", {
        required: "Campo obrigatório",
        email: "Este campo requer um endereço de e-mail válido",
        number: "Este campo requer um número válido",
        integer: "Este campo requer um número inteiro válido",
        url: "Este campo requer uma URL válida",
        tel: "Este campo requer um número de telefone válido",
        maxlength: "Este campo deve ter no máximo ${1} caracteres",
        minlength: "Este campo deve ter pelo menos ${1} caracteres",
        min: "O campo deve ter pelo menos ${1} caracteres",
        max: "Este campo deve ter no máximo ${1} caracteres",
        pattern: "Este campo deve corresponder ao padrão",
        equals: "Os campos não correspondem",
        default: "Este campo não é válido",
      });

      Pristine.setLocale("pt-BR");

      this.querySelectorAll("[mask]").forEach((input) =>
        VMasker(input).maskPattern(input.getAttribute("mask"))
      );
    }, 200);

    this.addEventListener("click", (event) => {
      if (typeof dataLayer !== "undefined") {
        dataLayer.push({
          event: "formStart",
          eventModel: {
            form_id: this.getAttribute("id"),
            trigger_by: "CuboSuite"
          }
        })
      }
    }, { once: true });

    this.addEventListener("submit", (event) => {
      event.preventDefault();

      const button = event.target.querySelector("button[type='submit']")
      const buttonLabel = button.querySelector("button[type='submit'] span") ? button.querySelector("button[type='submit'] span") : button.querySelector("button[type='submit']")

      localStorage.removeItem("cuboDeal");
      button.setAttribute("disabled", true);
      buttonLabel.innerText = "Enviando...";

      let form = this;
      let pristine = new Pristine(this);
      let valid = pristine.validate();

      if (!valid) {
        button.removeAttribute("disabled")
        buttonLabel.innerText = "Enviar";
        return
      }

      if (valid) {
        if (!props.pipe || !props.keycubo) {
          button.removeAttribute("disabled");
          buttonLabel.innerText = "Enviar";
          return alert("API KEY ou Funil não selecionados.");
        }

        let fields = form.querySelectorAll("input, select");

        let payload = {
          title: form.querySelector("[name='name']").value,
          peopleName: form.querySelector("[name='name']").value,
          peoplePhone: form.querySelector("[name='phone']").value,
          pipeId: props.pipe,
          customfields: [],
        };

        fields.forEach((field) => {
          let type = field.getAttribute("type");
          let name = field.getAttribute("name");
          let customfieldName = field.getAttribute("data-cf");

          if (name !== "name" && name !== "phone") {
            if (type === "select") {
              if (field.options[field.selectedIndex].value) {
                payload.customfields.push({
                  customfieldId: parseInt(field.getAttribute("name")),
                  value: field.options[field.selectedIndex].value,
                });
              }
            } else {
              const name = customfieldName.toLowerCase().split(" ").join("_");
              const queryString = window.location.search;
              const urlParams = new URLSearchParams(queryString);

              if (!field.value)
                field.value = urlParams.has(name) ? urlParams.get(name) : null;

              if (field.value) {
                payload.customfields.push({
                  customfieldId: parseInt(field.getAttribute("name")),
                  value: field.value,
                });
              }
            }
          }
        });

        payload.customfields = payload.customfields.filter((cf) => cf.value);

        fetch(
          `https://api.cubosuite.com.br/deals/external?${props.queue == true ? "asyncRequest=true" : ""
          }`,
          {
            method: "POST",
            headers: {
              "Content-type": "application/json",
              Authorization: `Bearer ${props.keycubo}`,
            },
            body: JSON.stringify(payload),
          }
        )
          .then((response) => response.json())
          .then((response) => {
            do {
              try {
                localStorage.setItem("cuboDeal", JSON.stringify(response));
              } catch (exception) {
                console.log(exception)
                break
              }
            } while (localStorage.getItem("cuboDeal") == null);

            button.removeAttribute("disabled");
            if (typeof dataLayer !== "undefined") {
              dataLayer.push({
                event: "formSubmit",
                eventModel: {
                  form_id: this.getAttribute("id"),
                  trigger_by: "CuboSuite"
                }
              })
            }

            if (props.redirect) {
              buttonLabel.innerText =  "Redirecionando...";
              window.location.href = props.redirect;
            }

            buttonLabel.innerText = "Enviado";
          });
      }
    });
  };

  const component = {
    isComponent(el) {
      if (el?.tagName === "form" && (el.getAttribute("componentName") === 'cubosuiteCreate' || el.getAttribute("componentName") === 'Cubosuite-form')) {
        return { type: "cubosuiteCreate" };
      }
    },
    model: {
      defaults: {
        tagName: "form",
        attributes: { method: "POST", componentName: "cubosuiteCreate" },
        droppable: false,
        traits: [
          {
            label: "Api key",
            type: "text",
            name: "keycubo",
            changeProp: true,
            required: true,
          },
          {
            label: "Funíl",
            type: "select",
            name: "pipe",
            changeProp: true,
            options: pipes,
            required: true,
          },
          {
            label: "Redirecionar para",
            type: "text",
            name: "redirect",
            changeProp: true,
          },
          {
            label: "Adicionar na fila",
            type: "select",
            name: "queue",
            changeProp: true,
            options: [
              { value: "true", name: "Sim" },
              { value: "false", name: "Não" },
            ],
            default: "false",
            required: true,
          },

          // {
          //   label: "Script ao enviar",
          //   type: "code",
          //   name: "scriptPostSend",
          //   changeProp: true,
          // },
        ],

        styles: `
          .has-danger .pristine-error.text-help{
            color: red;
            padding: 5px;
            margin: 5px;
            background-color: #ffdede;
            border-radius: 5px;
            width: fit-content;
          }

          .form-group.has-danger input, .form-group.has-danger select{
            border-color: red!important;
          } 
        `,

        pipe: "",
        keycubo: "",
        redirect: "",
        cookie: "",
        queue: "",

        script,

        "script-props": ["pipe", "keycubo", "redirect", "cookie", "queue"],

        components: (model) => {
          let inputs = `
          <div class="form-group">
            <label class="label">Nome</label>
            <input placeholder="Insira o nome" type="text" name="name" data-pristine-required class="form-control"/>
          </div>
          <div class="form-group">
            <label class="label">Telefone</label>
            <input placeholder="Insira o telefone" type="text" mask="(99) 99999-9999" data-pristine-required name="phone" data-pristine-minlength="15" class="form-control"/>
          </div>
          `;

          customfields.forEach((cf) => {
            let field = ``;
            if (cf.type === "text") {
              field = `
              <div class="form-group">
                <label class="label">${cf.name}</label>
                <input placeholder="Insira o ${cf.name}"  type="text" name="${cf.id}" class="form-control" data-cf="${cf.name}"/>
              </div>`;
            }

            if (cf.type === "selectbox") {
              let options = `<option selected disabled value=''>Selecione um ${cf.name}</option>`;

              cf.options.forEach((opt) => {
                options += `<option value="${opt.value}">${opt.value}</option>`;
              });

              field = `
              <div class="form-group">
                <label class="label">${cf.name}</label>
                <select name="${cf.id}" type="select" class="form-control" data-cf="${cf.name}">
                ${options}
                </select>
              </div>`;
            }

            inputs += field;
          });

          inputs += `<button data-gjs-type="cubosuiteButton" class="btn btn-primary"><span>Enviar</span></button>`;

          return inputs;
        },
      },
    },
    view: {},
  };

  return component;
};
