<template>
  <Loading :active="saving" :can-cancel="false" :is-full-page="true">
    <template #default>
      <div class="spinner spinner--cube-grid" style="width: 60px; height: 60px">
        <div class="sk-cube sk-cube1" style="background-color: #032e3f; animation-duration: 1.3s"></div>
        <div class="sk-cube sk-cube2" style="background-color: #032e3f; animation-duration: 1.3s"></div>
        <div class="sk-cube sk-cube3" style="background-color: #032e3f; animation-duration: 1.3s"></div>
        <div class="sk-cube sk-cube4" style="background-color: #032e3f; animation-duration: 1.3s"></div>
        <div class="sk-cube sk-cube5" style="background-color: #032e3f; animation-duration: 1.3s"></div>
        <div class="sk-cube sk-cube6" style="background-color: #032e3f; animation-duration: 1.3s"></div>
        <div class="sk-cube sk-cube7" style="background-color: #032e3f; animation-duration: 1.3s"></div>
        <div class="sk-cube sk-cube8" style="background-color: #032e3f; animation-duration: 1.3s"></div>
        <div class="sk-cube sk-cube9" style="background-color: #032e3f; animation-duration: 1.3s"></div>
      </div>
    </template>
  </Loading>

  <div class="pgbuilder">
    <div class="top-bar" id="top-bar" :class="{ 'display-none': previewMode }">
      <div class="break-points">
        <img class="logo" src="../assets/images/logo_cubo.png" />
        <!--<div class="devices" id="devices"></div>-->
      </div>
      <div class="options" id="option-menu">
        <a class="options-button return-cubo" id="return-cubo" href="https://app.cubosuite.com.br/landingpages"
          title="Sair" style="margin-bottom: 0px !important; margin-left:20px !important ;">
          <i class="bi bi-box-arrow-right"></i>
        </a>
        <div class="options-button icon-style active" id="style-button" ref="styleButton" title="Estilos"
          style="margin-bottom: 0px !important">
          <i class="bi bi-brush"></i>
          <input type="checkbox" id="btn-style" name="btn-style" ref="checkStyle" @click="toggleMenu('styles', $event)"
            class="btn-menu" />
        </div>
        <div class="options-button" id="config-button" title="Configurações" ref="configButton"
          style="margin-bottom: 0px !important; margin-right: 20px;">
          <i class="bi bi-sliders"></i>
          <input type="checkbox" id="btn-config" name="btn-config" ref="checkConfig" class="btn-menu"
            @click="toggleMenu('configs', $event)" />
        </div>
      </div>
    </div>
    <div class="side-bar" id="side-bar" :class="{ 'display-none': previewMode }">
      <div class="sider-bar-menu">
        <div id="options-side">
          <div class="options-button" id="components-button" title="Componentes" ref="blockButton">
            <i class="bi bi-columns-gap"></i>
            <input type="checkbox" id="btn-block" name="btn-block" class="btn-menu" ref="checkBlock"
              @click="toggleMenu('blocks', $event)" />
          </div>
          <div class="options-button" id="layers-button" ref="layerButton" title="Camadas" style="font-size: 23px;">
            <i class="bi bi-layers"></i>
            <input type="checkbox" id="btn-layer" name="btn-layer" ref="checklayer" class="btn-menu"
              @click="toggleMenu('layers', $event)" />
          </div>
        </div>
        <div class='divide'></div>
        <div id="blocks" ref="blocks"></div>
      </div>
      <div style="overflow-y: hidden; max-height: 100%">
        <div class="side-bar-menu-content">
          <div class="options-content" id="blocks-preset" ref="blocksPreset">
            <!--<div class="filters">
                  <button class="btn-search"></button>
                  <input type="search" id="serchBlock" @keyup="filterBlocks($event)" placeholder="Proucurar blocos..."
                    title="Procurar" />
                </div> -->
          </div>
          <div class="options-content" id="layers" ref="layers"></div>
        </div>
      </div>
    </div>

    <div id="gjs" ref="builder" :class="{ 'inpreview': previewMode }"></div>

    <div class="side-bar" id="side-bar-style" :class="{ 'display-none': previewMode }">
      <div style="overflow-y: hidden; max-height: 100%; width: 100%;">
        <div class="side-bar-menu-content">
          <div class="options-content display-block" id="style-manager" ref="styleManager">
            <div id="selector" ref="selectorManager"></div>
            <div id="style"></div>
          </div>
          <div class="options-content" id="config-manager" ref="configManager">
            <span class="title-manager">Configurações</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
//Imports
import "grapesjs/dist/css/grapes.min.css";
import "../assets/css/page-builder.css";
import "grapesjs-plugin-toolbox/dist/grapesjs-plugin-toolbox.min.css";
import "grapesjs-component-code-editor/dist/grapesjs-component-code-editor.min.css";
import "grapesjs-rulers/dist/grapesjs-rulers.min.css";
import ptBR from "../locale";

import Loading from "vue-loading-overlay";
import { ofetch } from "ofetch";
import { ref, onMounted } from "vue";
import grapesjs from "grapesjs";
import { useFetch, useStorage } from "@vueuse/core";
import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2";

import cuboBasics from "../plugins/cubo-basics";
import cuboBlocks from "../plugins/cubo-blocks";
import parserPostCSS from "grapesjs-parser-postcss";
import customCode from "grapesjs-custom-code";
import scriptEditor from "grapesjs-script-editor";
import borders from "grapesjs-plugin-borders";
import toolbox from "grapesjs-plugin-toolbox";
import codeEditor from "grapesjs-component-code-editor";
import rulers from "grapesjs-rulers";
import typed from "grapesjs-typed";
import lory from "../plugins/lory";
import toolbar from "../plugins/toolbar";
// import fonts from '@silexlabs/grapesjs-fonts';
// import saveDelay from '@silexlabs/grapesjs-storage-rate-limit'

import cuboForm from "../plugins/cubosuite-form";
import input from "../plugins/input";

//Variables
const router = useRouter();
const token = useStorage("pagebuilder-token", "");
const route = useRoute();
const saving = ref(false);
const loaderText = ref("");
const saveType = ref("");
const url = ref("")
const domain = ref("")
const previewMode = ref(false);
const editor = ref(null);
//Refs
const checkBlock = ref(null);
const blockButton = ref(null);
const blocksPreset = ref(null);
const builder = ref(null)
const blocks = ref(null);
const checklayer = ref(null);
const layerButton = ref(null);
const layers = ref(null);
const checkConfig = ref(null);
const configButton = ref(null);
const configManager = ref(null);
const checkStyle = ref(null);
const styleButton = ref(null);
const styleManager = ref(null);
const selectorManager = ref(null);

console.log('token', token.value)
console.log('params', route.params)

if (!token.value || token.value.length === 0) {
  console.log('if1')
  window.location.href = `${process.env.VUE_APP_URL_CUBO}/login?redirect=${process.env.VUE_APP_URL_BASE}/page/${route.params.id}&redirectType=pagebuilder`;
}

const pipes = await ofetch('https://api.cubosuite.com.br/pipes?limit=99999', {
  headers: {
    Authorization: `Bearer ${token.value}`,
  },
})
const forms = await ofetch('https://api.cubosuite.com.br/forms?limit=99999', {
  headers: {
    Authorization: `Bearer ${token.value}`,
  },
})
const customfields = await ofetch('https://api.cubosuite.com.br/customfields?limit=9999&status=active', {
  headers: {
    Authorization: `Bearer ${token.value}`,
  },
})

onMounted(async () => {
  console.log('onMounted')
  editor.value = grapesjs.init({
    container: "#gjs",
    height: "calc(100vh - 53px)",
    width: "calc(100% - (5% + 14%) )",
    fonts: [{ name: 'ABeeZee', value: '"ABeeZee", sans-serif', variants: [] }],

    i18n: {
      localeFallback: "pt",
      messages: ptBR,
    },

    assetManager: {
      storageType: "",
      autosave: true,
      stepsBeforeSave: 1,
      storeOnChange: true,
      storeAfterUpload: true,
      multiUpload: true,
      embedAsBase64: true,
      uploadFile: async function (e) {
        loaderText.value = "Enviando arquivo";
        saving.value = true;
        const files = e.dataTransfer ? e.dataTransfer.files : e.target.files;

        if (files.length > 0) {
          const formData = new FormData();
          formData.append("file", files[0], files[0].name);

          const upload = await useFetch(
            `${process.env.VUE_APP_URL_API}/files`,
            {
              headers: {
                Authorization: `Bearer ${token.value}`,
              },
            }
          )
            .post(formData)
            .json();

          editor.value.AssetManager.add(upload.data.value.link);
          saving.value = false;
          Swal.fire({
            text: "Arquivo enviado.",
            showConfirmButton: false,
            toast: true,
            position: "top-start",
            timer: "1000",
            timerProgressBar: true,
          });
        }
      },
      i18n: {
        locale: `pt`,
      },
    },

    canvas: {
      styles: [
        "https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css",
        "https://fonts.googleapis.com/css?family=ABeeZee:regular,italic&display=swap",
        "https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&display=swap",
      ],
      scripts: [
      ],
    },

    storageManager: {
      id: "",
      type: "cubo",
      autosave: false,
      stepsBeforeSave: 1,
      autoload: true,
      onStore: (data, editor) => {
        saving.value = true;
        const pagesHtml = editor.Pages.getAll().map((page) => {
          const component = page.getMainComponent();
          return {
            html: editor.getHtml({ component }),
            css: editor.getCss({ component }),
          };
        });
        return { data, pagesHtml };
      },
    },

    plugins: [
      toolbar,
      parserPostCSS,
      customCode,
      scriptEditor,
      borders,
      toolbox,
      codeEditor,
      rulers,
      typed,
      lory,
      // fonts,
      // saveDelay,

      cuboBasics,
      cuboBlocks,
      cuboForm,
    ],

    pluginsOpts: {
      [borders]: {
        selected: "#1D4353",
        resizer: "#1D4353",
        toolbar: "#1D4353",
        badge: "#1D4353",
        highlighter: "#1D4353",
      },
      [toolbox]: {
        panels: true,
        traitsInSm: false,
        breadcrumbs: true,
        gridBlock: false,
      },
      [cuboForm]: {
        token: token.value,
        pipes: pipes.data,
        forms: forms.data,
        customfields: customfields.data,
      },
      // [fonts]: {
      //   api_key: process.env.VUE_APP_GOOGLE_FONTS_API
      // }
    },

    deviceManager: {
      devices: [
        {
          id: "notebook",
          name: "Notebook (1366 x 768)",
          width: "97%",
          widthMedia: "1366",
        },
        {
          id: "comp",
          name: "Computador (1920 x 1080) ",
          width: "",
        },
        {
          id: "tab",
          name: "Tablet (992 x 1118)",
          width: "768px",
          widthMedia: "992px",
        },
        {
          id: "celular",
          name: "Celular (412 x 915)",
          width: "320px",
          widthMedia: "575px",
        },
      ],
    },

    blockManager: {
      appendTo: blocks.value,
    },

    styleManager: {
      appendTo: styleManager.value,
    },

    selectorManager: {
      appendTo: selectorManager.value,
      componentFirst: true,
    },

    layerManager: {
      appendTo: layers.value,
    },

    traitManager: {
      appendTo: configManager.value,
    },
  });

  /*  editor.value.Panels.addPanel({
      id: "devices",
      el: ".devices",
      buttons: [
        {
          id: "set-device-desktop",
          command: {
            run(e) {
              e.setDevice("Desktop");
            },
            stop() {},
          },
          className: "devices-break device-desktop gjs-pn-active gjs-four-color",
          attributes: { title: "Desktop", id: "desktop" },
          active: 1,
        },
        {
          id: "set-device-notebook",
          command: {
            run(e) {
              e.setDevice("Notebook");
            },
            stop() {},
          },
          className: "devices-break device-note",
          attributes: { title: "Notebook", id: "notebook" },
        },
        {
          id: "set-device-tablet",
          command: {
            run(e) {
              e.setDevice("Tablet");
            },
            stop() {},
          },
          className: "devices-break device-tablet",
          attributes: { title: "Tablet", id: "tablet" },
        },
        {
          id: "set-device-mobile",
          command: {
            run(e) {
              e.setDevice("Mobile portrait");
            },
            stop() {},
          },
          className: "devices-break device-mobile",
          attributes: { title: "Mobile", id: "mobile" },
        },
      ],
    });
  */

  /*  editor.value.Panels.addPanel({
      id: "other",
      el: ".save",
      buttons: [
        {
          id: "save",
          className: "icon-save",
          command: function () {
            saveType.value = "direct";
            editor.value.store();
          },
          attributes: { title: "Salvar" },
        },
      ],
    });*/

  editor.value.Panels.addPanel({
    id: "teste",
    el: ".options",
    buttons: [
      /* {
          id: "fonts",
          command: "open-fonts",
          attributes: { title: "Instalar fontes" },
          label: '<i class="bi bi-file-earmark-font"></i>',
        },*/
      {
        id: "undo",
        command: "core:undo",
        attributes: { title: "Desfazer" },
        label: '<i class="bi bi-arrow-counterclockwise"></i>',
      },
      {
        id: "redo",
        command: "core:redo",
        label: '<i class="bi bi-arrow-clockwise"></i>',
        attributes: { title: "Refazer" },
      },
      /*   {
           id: "visibility",
           active: true,
           label: '<i class="bi bi-border"></i>',
           command: "sw-visibility",
           attributes: { title: "Linhas guias" },
         },
         {
           id: "fullscreen",
           command: "core:fullscreen",
           label: '<i class="bi bi-arrows-fullscreen"></i>',
           attributes: { title: "Tela cheia" },
         },*/
      {
        id: "preview",
        command: "preview",
        label: '<i class="bi bi-eye"></i>',
        attributes: { title: "Pré-Visualizar" },
      },
      /*  {
          attributes: {
            title: "Reguas",
          },
          label: '<i class="bi bi-rulers"></i>',
          context: "toggle-rulers",
          command: "ruler-visibility",
          id: "ruler-visibility",
        },*/
      {
        id: "save",
        command: function () {
          saveType.value = "direct";
          editor.value.store();
        },
        label: '<i class="bi bi-floppy"></i>',
        attributes: { title: "Salvar" },
      },
    ],
  });

  // editor.value.Blocks.getCategories().each((ctg) => ctg.set("open", false));

  editor.value.on("run:preview", () => {
    previewMode.value = true;
    editor.value.stopCommand("sw-visibility");
  });

  editor.value.on("stop:preview", () => {
    previewMode.value = false;
    editor.value.runCommand("sw-visibility");
  });

  editor.value.on("load", () => {

    const bm = editor.value.Blocks;
    const all = bm.getAll();
    const filter = all.filter((block) => block.get("fromPlugin") !== 'blocks');
    const filter2 = all.filter((block) => block.get("fromPlugin") === 'blocks');
    bm.render(filter)

    const newBlocksEl = bm.render(filter2, { external: true });
    document.getElementById('blocks-preset').appendChild(newBlocksEl);

    let styleManager = editor.value.StyleManager;
    let fontProperty = styleManager.getProperty("typography", "font-family");
    fontProperty.addOption({
      value: "Roboto, Arial, sans-serif",
      name: "Roboto",
    });
    fontProperty.addOption({
      value: "Open Sans, Arial, sans-serif",
      name: "Open Sans",
    });
    fontProperty.addOption({
      value: "Montserrat, Arial, sans-serif",
      name: "Montserrat",
    });
    fontProperty.addOption({
      value: "Playfair Display, Arial, serif",
      name: "Playfair Display",
    });
    fontProperty.addOption({
      value: "Lato Display, Arial, sans-serif",
      name: "Lato",
    });
    fontProperty.addOption({
      value: "Merriweather, Arial, serif",
      name: "Merriweather",
    });
    fontProperty.addOption({ value: "Arvo, Arial, serif", name: "Arvo" });
    fontProperty.addOption({
      value: "Dosis, Arial, sans-serif",
      name: "Dosis",
    });
    fontProperty.addOption({
      value: "Merriweather, Arial, serif",
      name: "Merriweather",
    });
    fontProperty.addOption({
      value: "Bebas Neue, Arial, cursive",
      name: "Bebas Neue",
    });
    fontProperty.addOption({
      value: "Josefin Sans, Arial, sans-serif",
      name: "Josefin Sans",
    });
    fontProperty.addOption({
      value: "Abril Fatface, Arial, cursive",
      name: "Abril Fatface",
    });
    fontProperty.addOption({
      value: "Exo 2, Arial, sans-serif",
      name: "Exo 2",
    });
    fontProperty.addOption({
      value: "Pacifico, Arial, cursive",
      name: "Pacifico",
    });
    fontProperty.addOption({
      value: "League Spartan, Arial, sans-serif",
      name: "League Spartan",
    });
    fontProperty.addOption({
      value: "Oswald, Arial, sans-serif",
      name: "Oswald",
    });
    fontProperty.addOption({
      value: "Raleway, Arial, sans-serif",
      name: "Raleway",
    });
    fontProperty.addOption({
      value: "Outfit, Arial, sans-serif",
      name: "Outfit",
    });
    fontProperty.addOption({
      value: "Catamaran, Arial, sans-serif",
      name: "Catamaran",
    });
    fontProperty.addOption({
      value: "Sora, sans-serif",
      name: "Sora",
    });
  });

  // editor.value.TraitManager.addType("my-textarea-trait", {
  //   events: {
  //     keyup: "onChange",
  //   },
  //   onValueChange() {
  //     var traitModel = this.model;
  //     var selectedComponent = this.target;
  //     var inputValue = traitModel.get("value");
  //     selectedComponent.set("attributes", { comment: "true" });
  //   },

  //   /**
  //    * Returns the input element
  //    * @return {HTMLElement}
  //    */
  //   getInputEl() {
  //     var input = document.createElement("textarea");

  //     return input;
  //   },
  // });

  editor.value.StorageManager.add("cubo", {
    async load() {
      saving.value = true;
      loaderText.value = "Carregando Página"
      console.log('storemanager', { process: process.env, token, route })
      const { data: landing } = await useFetch(
        `${process.env.VUE_APP_URL_API}/landings/${route.params.id}`,
        {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        },
        {
          onFetchError(ctx) {
            console.log(ctx.response.status);
            if (ctx.response.status === 401 || ctx.response.status === 404) {
              router.push({
                name: "Unauthorized",
              });
            }
          },
        }
      ).json();

      url.value = landing.value.url;
      domain.value = landing.value.domain.domain;
      saving.value = false;

      return {
        assets: JSON.parse(landing.value.assets),
        styles: JSON.parse(landing.value.styles) || landing.value.css,
        pages: [
          {
            frames: [
              {
                component: {
                  type: "wrapper",
                  stylable: [
                    "background",
                    "background-color",
                    "background-image",
                    "background-repeat",
                    "background-attachment",
                    "background-position",
                    "background-size",
                  ],
                  components: [JSON.parse(landing.value.components)],
                },
              },
            ],
          },
        ],
      };
    },

    async store(data) {
      loaderText.value = "Salvando Alterações";

      const css = editor.value.getCss();
      const head = `<style type="text/css">${css}</style>`;

      const payload = {
        assets: JSON.stringify(data.data.assets),
        components: JSON.stringify(
          data.data.pages[0].frames[0].component.components
        ),
        css: data.pagesHtml[0].css,
        html: data.pagesHtml[0].html,
        styles: JSON.stringify(data.data.styles),
        head,
      };

      saving.value = false;

      Swal.fire({
        text: "Página salva.",
        showConfirmButton: false,
        toast: true,
        position: "top-start",
        timer: "2000",
        timerProgressBar: true,
        footer: `<a href="https://${domain.value}/${url.value}" target="_BLANK">Visualizar Página</a>`,
      });

      await useFetch(
        `${process.env.VUE_APP_URL_API}/landings/${route.params.id}`,
        {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        }
      )
        .put(payload)
        .json();

      return;
    },
  });
});



/*const dropBlocks = (event) => {
  const query = event.target.value;
  const bm = editor.value.Blocks;
  const all = bm.getAll();

  if (query === "all") {
    return bm.render(all.value);
  }

  const filter = all.filter((block) => block.get("fromPlugin") === query);
  return bm.render(filter);
};

const filterBlocks = (event) => {
  const query = event.target.value;
  const bm = editor.value.Blocks;
  const all = bm.getAll();
  let filter = all;

  (filter = all.filter((block) => {
    return (
      block
        .get("label")
        .toLowerCase()
        .replace(/[ç]/, "c")
        .replace(/[àáâãäå]/, "a")
        .replace(/[èéêë]/, "e")
        .replace(/[ìíîï]/, "i")
        .replace(/[òóôõö]/, "o")
        .replace(/[ùúûü]/, "u")
        .replace(/[^a-z]/gi, "")
        .indexOf(
          query
            .toLowerCase()
            .replace(/[ç]/, "c")
            .replace(/[àáâãäå]/, "a")
            .replace(/[èéêë]/, "e")
            .replace(/[ìíîï]/, "i")
            .replace(/[òóôõö]/, "o")
            .replace(/[ùúûü]/, "u")
            .replace(/[^a-z]/gi, "")
        ) > -1
    );
  })) || (filter = all.filter((block) => true));

  bm.render(filter);
};*/

const toggleMenu = (type, event) => {
  if (type === "blocks") {
    if (!event.target.checked) {
      blocksPreset.value.classList.remove("display-block");
      blockButton.value.classList.remove("active");
      builder.value.classList.remove("w-complete");
      checkBlock.value.checked = false;
      editor.value.refresh();
      return;
    }

    builder.value.classList.add("w-complete");

    blocksPreset.value.classList.add("display-block");
    blockButton.value.classList.add("active");


    layers.value.classList.remove("display-block");
    layerButton.value.classList.remove("active");

    checklayer.value.checked = false;

    editor.value.refresh();
  }

  if (type === "layers") {
    if (!event.target.checked) {
      layers.value.classList.remove("display-block");
      layerButton.value.classList.remove("active");
      builder.value.classList.remove("w-complete");
      checklayer.value.checked = false;
      editor.value.refresh();
      return;
    }

    builder.value.classList.add("w-complete");
    layers.value.classList.add("display-block");
    layerButton.value.classList.add("active");

    blocksPreset.value.classList.remove("display-block");
    blockButton.value.classList.remove("active");

    checkBlock.value.checked = false;

    editor.value.refresh();
    return;
  }

  if (type === "configs") {
    if (!event.target.checked) {
      editor.value.refresh();
      return;
    }

    configManager.value.classList.add("display-block");
    configButton.value.classList.add("active");

    styleManager.value.classList.remove("display-block");
    styleButton.value.classList.remove("active");

    checkStyle.value.checked = false;

    editor.value.refresh();
    return;
  }

  if (type === "styles") {
    if (!event.target.checked) {
      editor.value.refresh();
      return;
    }

    styleManager.value.classList.add("display-block");
    styleButton.value.classList.add("active");

    configManager.value.classList.remove("display-block");
    configButton.value.classList.remove("active");

    checkConfig.value.checked = false;

    editor.value.refresh();
    return;
  }
};
</script>
