export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              type:'div', 
              name: "Card Diferenciais",
              attributes: { class:'card-difference-1'},
              components: [
                {
                  type: 'image', 
                  attributes: { class: 'icon-difference-1', src: 'https://placehold.co/60x60'}, 
                }, 
                {
                  type: 'text', 
                  content: 'Lorem ipsum dolor ', 
                  attributes: { class: 'title-difference-1'},
                },
                {
                  type: 'text', 
                  content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.', 
                  attributes: { class: 'desc-difference-1'},
                }, 
              ],
              styles: `
              .card-difference-1{
                width: 27%; 
              }
              .icon-difference-1{
                width: 60px; 
              }
              .title-difference-1{ 
                font-size: 18px; 
                color: #363940; 
                margin-top: 15px; 
              }
              .desc-difference-1{
                font-size: 16px; 
                color: #95A1BB; 
                margin-top: 10px; 
              }
      
              @media (max-width: 992px){
                .card-difference-1{
                  width: 100%; 
                }
              }
           `,
             },
          },
          view: {
      
          },
};
  return component; 
};