export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              type: 'div',
              name: "Seção Diferenciais",
              attributes: { class: 'section' },
              components: [
                {
                  type: 'containerDifferences2',
                },
      
              ],
              styles: `
             .section{
               position:relative; 
               with:100vw; 
               padding: 75px 5%; 
             }
            `,
            },
          },
          view: {
      
          },
};
  return component; 
};