console.log('Iniciando CuboSuite Plugin')
import { State, City } from 'country-state-city';
import MicroModal from 'micromodal';
import formCreateBlock from "./blocks/formCreate.js";
import formUpdateBlock from "./blocks/formUpdate.js";
import formNewBlock from "./blocks/formNew.js";

import buttonComponent from './components/button.js'
import formCreateComponent from './components/formCreate.js'
import formUpdateComponent from './components/formUpdate.js'
import cuboFormRowComponent from './components/cuboFormRow.js'
import formNewComponent from './components/formNew.js'
console.log('Modulos importados')

export default (editor, opts = {}) => {

  console.log('Inicio da execução do plugin', opts)
  // const options = {...opts }
  const options = { ...{ State, City, MicroModal }, ...opts }
  console.log('Opções definidas')

  // Add components
  editor.DomComponents.addType("cubosuiteButton", buttonComponent(editor, options));
  console.log('Componente cubosuiteButton definido')
  editor.DomComponents.addType("cubosuiteCreate", formCreateComponent(editor, options));
  console.log('Componente cubosuiteCreate definido')
  editor.DomComponents.addType("cubosuiteUpdate", formUpdateComponent(editor, options));
  console.log('Componente cubosuiteUpdate definido')
  editor.DomComponents.addType("cuboFormRow", cuboFormRowComponent(editor, options));
  console.log('Componente cuboFormRow definido')
  editor.DomComponents.addType("formNew", formNewComponent(editor, options));
  console.log('Componente formNew definido')

  // Add blocks
  editor.BlockManager.add("cubosuite-create-block", formCreateBlock(editor, options));
  console.log('Bloco cubosuite-create-block definido')
  editor.BlockManager.add("cubosuite-update-block", formUpdateBlock(editor, options));
  console.log('Bloco cubosuite-update-block definido')
  editor.BlockManager.add("new-form-block", formNewBlock(editor, options));
  console.log('Bloco new-form-block definido')
}
